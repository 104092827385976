import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
// import { analytics } from '../../utils/common';
import history from '../../history';
import {
  arrayToMap,
  arrayToMapFocus,
  arrayToMapPressure,
  getPressureMap,
} from '../../utils';
import {
  PREFERENCES_CUSTOMER_PRESSURE,
  PREFERENCES_DROPDOWN_OPTIONS,
  PREFERENCES_DROPDOWN_OPTIONS_PRESSURE,
  PREFERENCES_FRONT_OPTIONS,
} from '../../utils/constants';
import FocusLimitModal from './focus-limit-modal';
import SvgFrontMain from './svg-front-main';

const selectStyles = {
  option: (cStyles, { data }) => ({
    ...cStyles,
    color: data.color,
  }),
  singleValue: (cStyles, { data }) => ({
    ...cStyles,
    color: data.color,
  }),
};

class FocusPressureFront extends React.Component {
  constructor(props) {
    super();
    this.state = {
      userOptions: this.updateUserOptions(
        'focus',
        props?.preferences.preferencesArray || [],
      ),
      userPressureOptions: this.updateUserOptions(
        'pressure',
        props?.preferences.preferencesArray || [],
      ),
      showFocusLimitModal: false,
      width: 0,
      step: parseInt(props.step, 10),
      editedData: {
        pressure: {},
        focus: {},
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.disableSubmitFlag = this.disableSubmitFlag.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.closeFocusLimitModal = this.closeFocusLimitModal.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  isMobile() {
    return this.state.width < 600;
  }

  options = PREFERENCES_FRONT_OPTIONS.map(obj => obj.title);

  dropdownOptionsFocus = PREFERENCES_DROPDOWN_OPTIONS;

  dropdownOptionsPressure = PREFERENCES_DROPDOWN_OPTIONS_PRESSURE;

  optionsMap = arrayToMap(PREFERENCES_FRONT_OPTIONS);

  getFocusMap() {
    if (_.isEmpty(this.state.userOptions)) {
      return {};
    }
    const userOptions = this.state.userOptions;
    const ary = userOptions.map(o => {
      const val = {};
      val[this.optionsMap[o.key].key] = o.value;
      return val;
    });
    return ary.reduce((m, o) => {
      const map = m;
      const key = Object.keys(o)[0];
      map[key] = o[key];
      return map;
    }, {});
  }

  getCustomerPressure() {
    const _preferencesArray =
      this.props.preferences && this.props.preferences.preferencesArray;
    const _customerPressure =
      _preferencesArray &&
      _preferencesArray.find(
        p => p.key === PREFERENCES_CUSTOMER_PRESSURE.CUSTOMER_PRESSURE,
      );
    const _customerPressureValue = _customerPressure && _customerPressure.value;
    return _customerPressureValue;
  }

  setUserOptions(value = 'USUAL') {
    return this.options.map(opt => ({ key: opt, value }));
  }

  updateUserOptions(type = 'focus', preferences = []) {
    return PREFERENCES_FRONT_OPTIONS.map(opt => {
      const preferenceOption = preferences.find(pref =>
        type === 'focus' ? opt.apiKey === pref.key : opt.apiPKey === pref.key,
      );
      return { key: opt.title, value: preferenceOption?.value || 'USUAL' };
    });
  }

  setPressureOption(index) {
    const copy = [...this.state.userPressureOptions];
    copy[index].value = '';
    this.setState({ userPressureOptions: copy });
  }

  numberOfAlreadySelected(option) {
    return this.state.userOptions.filter(opt => opt.value === option);
  }

  handleFocusChange(value, index) {
    // const alreadySelectedMoreCount = this.numberOfAlreadySelected('MORE')
    //   .length;
    // if (alreadySelectedMoreCount >= 3 && value.value === 'MORE') {
    //   this.setState({ showFocusLimitModal: true });
    //   // analytics('Max 3 Areas');

    //   return false;
    // }
    if (value.value === 'AVOID') {
      this.setPressureOption(index);
    }
    const editOpt = this.state.userOptions;
    editOpt[index].value = value.value;
    this.setState({ userOptions: editOpt });
    this.setFocusChange(editOpt, index, value);
    return true;
  }

  setFocusChange(editOpt, index, value) {
    const { editedData } = this.state;
    editedData.focus[editOpt[index].key] = value.value;
    this.setState({ editedData });
  }

  setPressureChange(editOpt, index, value) {
    const { editedData } = this.state;
    editedData.pressure[editOpt[index].key] = value.value;
    this.setState({ editedData });
  }

  handlePressureChange(value, index) {
    const editOpt = this.state.userPressureOptions;
    editOpt[index].value = value.value;
    this.setState({ userPressureOptions: editOpt });
    this.setPressureChange(editOpt, index, value);
  }

  handleSubmit() {
    const paramsFocus = arrayToMapFocus(
      this.state.userOptions,
      this.optionsMap,
    );
    const paramsPressure = arrayToMapPressure(
      this.state.userPressureOptions,
      this.optionsMap,
    );
    // this.logChangeAnalytics();
    // console.log(
    //   { ...paramsFocus, ...paramsPressure },
    //   this.state.userOptions,
    //   this.state.userPressureOptions,
    //   this.optionsMap,
    //   'onUpdateFront',
    // );
    this.props
      .updatePreferences({
        id: this.props.userId,
        preferences: { ...paramsFocus, ...paramsPressure },
      })
      .then(() => this.props.refetchUserPreferences());
  }

  // logChangeAnalytics() {
  //   logPreferenceChangeAnalytics(
  //     this.state.editedData,
  //     'Main/Mid Preferences',
  //     'Frontside',
  //   );
  // }

  renderOptionSection() {
    const { userOptions, userPressureOptions } = this.state;
    return this.options.map((opt, index) => {
      const optFocusValue = _.find(userOptions, { key: opt }).value;
      const optFocusIndex = _.findIndex(this.dropdownOptionsFocus, {
        value: optFocusValue,
      });
      const optPressureValue = _.find(userPressureOptions, { key: opt }).value;
      const optPressureIndex = _.findIndex(this.dropdownOptionsPressure, {
        value: optPressureValue,
      });
      const pressureValue =
        optFocusValue === 'AVOID'
          ? { value: null, label: 'NA' }
          : this.dropdownOptionsPressure[
              optPressureIndex > -1 ? optPressureIndex : 0
            ];
      return (
        <div className="optionLists" key={`opt-${opt}`}>
          <div className="optionBlock">
            <p>{opt}</p>
          </div>

          <div className="optionBlock pr-2">
            <Select
              // className={'w-36'}
              options={this.dropdownOptionsFocus}
              isSearchable={false}
              defaultValue={this.dropdownOptionsFocus[optFocusIndex]}
              // components={{ Placeholder: <span>NA</span> }}
              value={this.dropdownOptionsFocus[optFocusIndex]}
              onChange={value => (
                this.handleFocusChange(value, index), this.handleSubmit()
              )}
              styles={selectStyles}
            />
          </div>
          <div className={this.isStep1() ? 'hide-mobile' : 'optionBlock'}>
            <Select
              // className={'w-36'}
              options={this.dropdownOptionsPressure}
              isSearchable={false}
              value={pressureValue}
              defaultValue={pressureValue}
              isDisabled={!pressureValue.value}
              onChange={value => (
                this.handlePressureChange(value, index), this.handleSubmit()
              )}
            />
          </div>
          <br />
        </div>
      );
    });
  }

  disableSubmitFlag() {
    const { fetchingPreferences, updatingPreferences } = this.props.preferences;
    return fetchingPreferences || updatingPreferences;
  }

  updateOptionPersisted() {
    const { preferences } = this.props.preferences;
    let { userOptions, userPressureOptions } = this.state;
    const { optionsMap } = this;
    userOptions = userOptions.map(o => {
      const persistedPref = _.find(preferences.customer_preferences, {
        key: optionsMap[o.key].apiKey,
      });
      const updatedUserOption = o;
      updatedUserOption.value =
        persistedPref && persistedPref.value
          ? persistedPref.value.toLocaleUpperCase()
          : 'USUAL';

      return updatedUserOption;
    });

    userPressureOptions = userPressureOptions.map(o => {
      const key = optionsMap[o.key].apiPKey;
      const persistedPref = _.find(preferences.customer_preferences, {
        key,
      });
      const updatedUserOption = o;
      updatedUserOption.value =
        persistedPref && persistedPref.value
          ? persistedPref.value.toLocaleUpperCase()
          : 'USUAL';

      return updatedUserOption;
    });
    this.setState({ userOptions, userPressureOptions });
  }

  closeFocusLimitModal() {
    this.setState({ showFocusLimitModal: false });
  }

  renderFocusLimitModal() {
    return (
      <FocusLimitModal
        isOpen={this.state.showFocusLimitModal}
        contentLabel="Focus Limit"
        close={this.closeFocusLimitModal}
      >
        To Make sure that your therapist has adequate
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={() => this.setState({ showFocusLimitModal: false })}
        >
          Okay
        </button>
      </FocusLimitModal>
    );
  }

  isStep1() {
    return this.state.step === 1;
  }

  isStep2() {
    return this.state.step === 2;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // this.props.fetchPreferences();
    // analytics('Focus for Frontside', null, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  render() {
    const { userId, appointmentId, pressure } = this.props;
    // const customerPressure = this.getCustomerPressure();
    const customerPressureValue = pressure ? `(${pressure})` : '';
    const focusMap = this.getFocusMap();
    const pressureMap = getPressureMap(this.state, this.optionsMap);

    return (
      <div
        className={`${this.props.className} pt-4 mt-4 border-red border-solid border-4 rounded-xl`}
      >
        <div className="MainContentWrap">
          <>
            <div className="prefFocusPressure flex-space p-btm-10">
              <h1 className="text-xl font-bold text-center">
                FRONT PRESSURE & FOCUS
              </h1>
              <div className="is-flex">
                <div className="lablesOptions mainLabelsWrap-X flex flex-items flex-direction-col mr-4">
                  <div className="mx---10 mt-4 figureNext">
                    <button
                      className="bg-transparent hover:bg-blue-700 text-blue-500 hover:text-white border border-solid border-blue-500 font-bold py-2 px-4 rounded-full mr-2"
                      onClick={() => {
                        // this.props.setShowPreference(MASSAGE);
                        const baseRoute = '/guest';
                        return history.push(
                          `${baseRoute}/preferences/${userId}`,
                        );
                      }}
                    >
                      Back to Guest Details
                    </button>
                    <button
                      className="bg-transparent hover:bg-blue-700 text-blue-500 hover:text-white border border-solid border-blue-500 font-bold py-2 px-4 rounded-full ml-2"
                      onClick={() => {
                        // this.props.setShowPreference(BACK);
                        return history.push(
                          `/guest/view-guest-details/${userId}/back-preferences`,
                        );
                      }}
                    >
                      View Back Preferences
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="optionSelectionBlock">
              <div className="prefFocusPressure flex-space">
                <div className="is-flex min-w-500">
                  <div className="lablesOptions mainLabelsWrap-X flex flex-items flex-direction-col mr-4">
                    <div className="lablesOptions flex mainLabels">
                      <div className="optionBlock" />
                      <div className="optionBlock">
                        <div className="lables amoutOfTime">
                          <div>AMOUNT OF TIME</div>
                        </div>
                      </div>
                      <div className="optionBlock">
                        <div className="lables amoutOfTime">
                          Pressure<span>{customerPressureValue}</span>
                        </div>
                      </div>
                    </div>
                    {this.renderOptionSection()}
                  </div>
                </div>
                <div className="text-center is-flex">
                  <div className="figureNext flex-items-zero flex-center mt30 front">
                    <div className="svgFigure">
                      {focusMap && (
                        <SvgFrontMain
                          focusMap={focusMap}
                          pressureMap={pressureMap}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.renderFocusLimitModal()}
          </>
        </div>
        {/* <div className="FootContentWrap">
          <Footer hideMobile />
        </div> */}
      </div>
    );
  }
}

FocusPressureFront.propTypes = {
  fetchPreferences: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  preferences: PropTypes.object,
  history: PropTypes.object,
  step: PropTypes.string,
  resetUpdatedPreferences: PropTypes.func,
  isFromSummary: PropTypes.bool,
};

export default FocusPressureFront;
