import * as React from 'react';
import { Loading, useQuery } from 'react-admin';

import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { GiftCardPurchaser } from './gift-card-purchaser';
import { GiftCardInfo } from './gift-card-info';
import { GiftCardRecipient } from './gift-card-recipient';

export const GiftDetails = () => {
  let { giftCardId } = useParams();

  // const [showPreference, setShowPreference] = React.useState(MASSAGE);
  // console.log(userId, appointmentId, 'useParams');

  const {
    data: giftDetails,
    loading: loadGiftDetails,
    // error: giftDetailsError,
    // refetch: refetchGiftDetails,
  } = useQuery({
    type: 'getOne',
    resource: 'gift',
    payload: { id: giftCardId },
  });

  const giftCardPurchaserProps = {
    giftDetails,
  };

  const giftCardInfoProps = { giftDetails };

  const giftCardRecipientProps = { giftDetails };
  return (
    <>
      <Title title="Gift Details" />
      <CardContent>
        {loadGiftDetails && <Loading />}
        {!loadGiftDetails && (
          <div className="details-display">
            <div>
              <GiftCardPurchaser {...giftCardPurchaserProps} />
              <GiftCardInfo {...giftCardInfoProps} />
            </div>
            <div>
              <GiftCardRecipient {...giftCardRecipientProps} />
            </div>
          </div>
        )}
      </CardContent>
    </>
  );
};
