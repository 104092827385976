import * as React from 'react';
import { Loading } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { FocusPressureBack } from './focus-pressure-back';
import {
  fetchPreferences,
  updatePreferences,
} from '../../services/preferences';
import { MassagePreferences } from './massage-preferences';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import FocusPressureFront from './focus-pressure-front';
import { BACK, FRONT, MASSAGE } from '../../utils/constants';
import { Details } from './details';
import { useAppointment } from '../../hooks/useAppointment';
import { APPOINTMENT_STATUS } from '../../utils/constants';

export const GuestDetails = () => {
  let { userId, appointmentId } = useParams();

  const [showPreference, setShowPreference] = React.useState(MASSAGE);

  const {
    aromaTherapy,
    lighting,
    temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
    userPreferences,
    loadingUserPreferences,
    // userPreferencesError,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const {
    data: appointment,
    loading: loadAppointment,
    // error: appointmentError,
    refetch: refetchAppointment,
  } = useAppointment({ appointmentId });

  const detailsProps = {
    refetchAppointment,
    refetchUserPreferences,
    fetchPreferences,
    updatePreferences,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    appointmentId,
    userId,
    appointment,
  };

  const massagePreferencesProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    aromaTherapy,
    lighting,
    temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
    appointmentId,
    userId,
    appointment,
  };

  const focusPressureFrontProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    userId,
    // history,
    step: '2',
    // resetUpdatedPreferences,
    isFromSummary: true,
  };

  const focusPressureBackProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    userId,
    // history,
    step: '2',
    // resetUpdatedPreferences,
    isFromSummary: true,
  };

  const showCompleteStatus =
    appointment?.status === APPOINTMENT_STATUS.COMPLETE;

  const showCancelledStatus =
    appointment?.status === APPOINTMENT_STATUS.CANCELLED;

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Guest Details" />
      <CardContent>
        {loadAppointment && <Loading />}
        {!loadAppointment && (
          <>
            <div className="mb-2 mt-6">
              {showCompleteStatus && (
                <h2
                  className="m-2 block text-3xl text-center"
                  style={{ color: 'green' }}
                >
                  COMPLETE
                </h2>
              )}
              {showCancelledStatus && (
                <h2
                  className="m-2 block text-3xl text-center"
                  style={{ color: 'red' }}
                >
                  CANCELLED
                </h2>
              )}
            </div>
            <div class="flex flex-row">
              <Details {...detailsProps} />
              {showPreference === MASSAGE && (
                <MassagePreferences {...massagePreferencesProps} />
              )}
              {showPreference === FRONT && (
                <FocusPressureFront
                  className="w-1/2 m-4"
                  {...focusPressureFrontProps}
                />
              )}
              {showPreference === BACK && (
                <FocusPressureBack
                  className="w-1/2 m-4"
                  {...focusPressureBackProps}
                />
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
