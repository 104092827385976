import CloseIcon from '@material-ui/icons/Close';
import momentTimezone from 'moment-timezone';
import React, { useState } from 'react';
import {
  DateTimeInput,
  EditContextProvider,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useEditController,
  useQuery,
  useRefresh
} from 'react-admin';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { customDataProvider } from '../data-provider';
import { getAppointmentDateTime, getDateTime, isBreakroom } from '../utils';
import { APPOINTMENT_STATUS } from '../utils/constants';

const validateTime = [required()];
const validateService = [required()];
const validateTherapist = [required()];

export const AppointmentEdit = ({
  props,
  appointment,
  callBack,
  therapists,
  permissions,
  setShowLoader,
  selectedLocation,
}) => {
  const refresh = useRefresh();
  const [showModal, setShowModal] = useState(false);

  const handleSave = form => {
    const apptDate = appointment?.start_at?.slice(0, 10);
    if (
      appointment?.no_show === 0 &&
      appointment.status !== APPOINTMENT_STATUS.CANCELLED
    ) {
      const { service, therapist, slotTime } = form;
      const payload = {
        service_id: service.id,
        therapist_id: therapist.id,
        slot_time: getDateTime(slotTime, true),
      };
      setShowLoader(true);
      customDataProvider
        .update('appointment', { id: appointment?.id, action: 'edit', payload })
        .then(() => {
          callBack && callBack();
          setShowLoader(false);
          // refresh();
        })
        .catch(error => error && setShowLoader(false));
    }
  };

  const EditAppointmentToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={handleSave} />
    </Toolbar>
  );

  const controllerProps = useEditController({
    basePath: `/appointments/${appointment.id}`,
    permissions: 'admin',
    resource: 'appointment',
    id: appointment.id,
  });
  const data = controllerProps.record;

  const { data: services } = useQuery({
    type: 'getList',
    resource: 'services',
    payload: { location_id: selectedLocation },
  });

  const getActiveServices = () => {
    const activeServices = services?.filter(s => s.title !== 'mini');
    return activeServices?.map(s => {
      return { ...s, name: s.label };
    });
  };

  const modifiedTherapists = () => {
    const therapistsList = JSON.parse(localStorage.getItem('therapistsList'));
    return therapistsList?.map(t => ({
      ...t,
      name: `${t.first_name} ${t.last_name}`,
    }));
  };
  const handleAppointmentUpdate = action => {
    setShowLoader(true);
    customDataProvider
      .update('appointment', {
        id: appointment?.id,
        action: action,
        payload: {},
      })
      .then(() => {
        callBack && callBack();
        setShowLoader(false);
        // refresh();
      })
      .catch(error => error && setShowLoader(false));
  };
  const userId = appointment?.user_id;
  const apptTime = momentTimezone
    .utc(appointment?.start_at)
    .tz(appointment?.timezone)
    .format('YYYY-MM-DDTHH:mm');

  const noShowModal = () => {
    const header = 'Mark No-show';
    const buttonText = 'Yes';
    const bodyContent = 'Are you sure you want to no show this appointment?';
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">{bodyContent}</div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue button-red"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              onClick={() => {
                setShowModal(false);
                handleAppointmentUpdate('noshow');
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const confirmModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {noShowModal()}
      <EditContextProvider value={controllerProps} style={{ width: '100%' }}>
        <div className="details-display grid4060">
          <div className="font-bold">Date/Time</div>
          {
            <div className="break-all">
              {isBreakroom(permissions) ? (
                <span>
                  {getAppointmentDateTime(
                    appointment?.start_at,
                    appointment?.end_at,
                    appointment?.timezone,
                  )}
                </span>
              ) : (
                <Link
                  to={`/guest/appointment/${appointment?.id}/view-guest-details/${appointment?.user_id}`}
                  className="underline"
                >
                  {getAppointmentDateTime(
                    appointment?.start_at,
                    appointment?.end_at,
                    appointment?.timezone,
                  )}
                </Link>
              )}
            </div>
          }
          <div className="font-bold">Guest</div>
          {
            <div className="break-all">
              {isBreakroom(permissions) ? (
                <div>{appointment?.user?.first_name}</div>
              ) : (
                <>
                  <Link
                    className="underline"
                    to={`/guest/${appointment?.user_id}`}
                  >
                    {appointment?.user?.first_name}{' '}
                    {appointment?.user?.last_name}
                  </Link>
                </>
              )}
            </div>
          }
          {appointment?.status === APPOINTMENT_STATUS.CONFIRMED && (
            <>
              <div className="font-bold">Mark as No Show</div>
              <div>
                <button
                  className="button-blue mr-6 button-red"
                  onClick={() => confirmModal()}
                >
                  No Show
                </button>
              </div>
            </>
          )}
        </div>
        <SimpleForm
          initialValues={{
            service: appointment?.service?.id,
            therapist: appointment?.therapist?.id,
            slotTime: apptTime,
          }}
          toolbar={<EditAppointmentToolbar />}
        >
          <DateTimeInput
            source="slotTime"
            optionText="name"
            label="Start Time"
            style={{ width: '100%' }}
          />
          <SelectInput
            source="service.id"
            optionText="name"
            choices={getActiveServices()}
            label="Service"
            style={{ width: '100%' }}
            validate={validateService}
          />
          <SelectInput
            source="therapist.id"
            choices={modifiedTherapists()}
            label="Therapist"
            style={{ width: '100%' }}
            validate={validateTherapist}
          />
        </SimpleForm>
      </EditContextProvider>
    </>
  );
};
