import { useEffect, useState } from 'react';
import { client as apiClient } from '../services/api-client';
import { mapLocationReaderId } from '../utils';
import { fetchGiftPaymentIntent } from './gift-queries';

const useStripeTerminal = giftDetails => {
  const [connectionStatus, setConnectionStatus] = useState('not_connected');
  const [terminal, setTerminal] = useState(null);
  const [discoveredReaders, setDiscoveredReaders] = useState([]);
  const [discoveringReaders, setDiscoveringReaders] = useState(false);
  const [discoveryWasCancelled, setDiscoveryWasCancelled] = useState(false);

  const [reader, setReader] = useState(null);
  const [connectingToReader, setConnectingToReader] = useState(false);

  const [usingSimulator, setUsingSimulator] = useState(false);

  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [collectingPayment, setCollectingPayment] = useState(false);

  useEffect(() => {
    async function initStripeTerminal() {
      const stripeTerminal =
        window.StripeTerminal &&
        window.StripeTerminal.create({
          // 1c. Create a callback that retrieves a new ConnectionToken from the example backend
          onFetchConnectionToken: async () => {
            let connectionTokenResult = await apiClient('connection-token', {
              method: 'POST',
              authNeeded: true,
            });
            return connectionTokenResult.secret;
          },
          // 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
          // You can use this callback to alert your user that the reader is no longer connected and will need to be reconnected.
          onUnexpectedReaderDisconnect: () => {
            alert('Unexpected disconnect from the reader');
            setReader(null);
          },
          // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
          // You can use this callback to update your UI with the reader's connection status.
          onConnectionStatusChange: ev => {
            setConnectionStatus(ev.status);
          },
        });
      setTerminal(stripeTerminal);
    }

    initStripeTerminal();
  }, []);

  const discoverReaders = async (simulate = false) => {
    setDiscoveryWasCancelled(false);
    setDiscoveringReaders(true);
  const discoverResult = await terminal.discoverReaders({
      simulated: simulate,
    });   
    setDiscoveringReaders(false);
    if (discoverResult.error) {
      throw new Error(discoverResult.error);
    } else {
      if (discoveryWasCancelled) return;
      setDiscoveredReaders(discoverResult.discoveredReaders);
      return discoverResult.discoveredReaders;
    }
  };

  const cancelDiscoverReaders = () => {
    setDiscoveryWasCancelled(true);
    setDiscoveringReaders(false);
  };

  const connectToReader = async selectedReader => {
    setConnectingToReader(true);
    const connectResult = await terminal.connectReader(selectedReader);
    console.log('5. CONNECTION TO READER RESULT:', connectResult);
    setConnectingToReader(false);
    setDiscoveredReaders([]);
    if (connectResult.error) {
      console.log('##########################################');
      console.log('CONNECTION TO READER FAILED!');
      console.log(connectResult.error);
      console.log('##########################################');
      throw new Error(connectResult.error);
    } else {
      setUsingSimulator(selectedReader.id === 'SIMULATOR');
      setDiscoveredReaders([]);
      setReader(connectResult.reader);
      console.log('6. RETURNING CONNECTION RESULT...');
      return connectResult;
    }
  };

  const connectToSimulator = async () => {
    try {
      const simulatedReaders = await discoverReaders(true);
      return await connectToReader(simulatedReaders[0]);
    } catch (e) {
      throw e;
    }
  };

  const disconnectReader = async () => {
    await terminal?.disconnectReader();
    setReader(null);
  };

  const fetchPaymentIntentSecret = async (amount, location_id) => {
    const intentSecret = paymentIntentSecret
      ? paymentIntentSecret
      : (await fetchGiftPaymentIntent(amount, location_id)).client_secret;
    setPaymentIntentSecret(intentSecret);
    return intentSecret;
  };

  const collectCardPayment = async (amount, location_id) => {
    setCollectingPayment(true);
    const intentSecret = await fetchPaymentIntentSecret(amount, location_id);
    console.log('6. INTENT SECRET:', intentSecret);
    // Read a card from the customer
    process.env.REACT_APP_NODE_ENV === 'development' &&
      terminal.setSimulatorConfiguration({
        testCardNumber: process.env.REACT_APP_STRIPE_TEST_CARD_NUMBER,
      });
    const result = await terminal.collectPaymentMethod(intentSecret);
    console.log('7. COLLECT PAYMENT METHOD RESULT:', result);
    setCollectingPayment(false);
    if (result.error) {
      console.log('Collect payment method failed:', result.error.message);
      throw new Error(result.error.message);
    } else {
      const confirmResult = await terminal.processPayment(result.paymentIntent);
      console.log('8. CONFIRM RESULT:', result);
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      // this.setState({ cancelablePayment: false });
      if (confirmResult.error) {
        console.log(`Confirm failed: ${confirmResult.error.message}`);
        throw new Error(confirmResult.error.message);
      }
      // else if (confirmResult.paymentIntent) {
      //   if (confirmResult.paymentIntent.status !== 'succeeded') {
      //     try {
      //       // Capture the PaymentIntent from your backend client and mark the payment as complete
      //       let captureResult = await this.client.capturePaymentIntent({
      //         paymentIntentId: confirmResult.paymentIntent.id,
      //       });
      //       setPaymentIntentSecret(null);
      //       console.log('Payment Successful!');
      //       return captureResult;
      //     } catch (e) {
      //       // Suppress backend errors since they will be shown in logs
      //       return;
      //     }
      //   }
      else {
        setPaymentIntentSecret(null);
        console.log('Single-message payment successful!');
        return confirmResult;
      }
    }
  };

  return {
    connectionStatus,
    discoveredReaders,
    discoveringReaders,
    discoveryWasCancelled,
    reader,
    connectingToReader,
    usingSimulator,
    terminal,
    collectingPayment,

    discoverReaders,
    cancelDiscoverReaders,
    connectToReader,
    connectToSimulator,
    disconnectReader,
    collectCardPayment,
  };
};

export default useStripeTerminal;
