/* eslint-disable import/no-anonymous-default-export */
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';
const { client } = require('../services/api-client');

const getReviewList = async (_resource, params) => {
  const role = localStorage.getItem('role');
  try {
    let { filter, pagination } = params;
    if (role === 'therapist') {
      filter.is_approved = 1;
      filter.share_to_therapist = 1;
      filter.rating = 5;
    } else {
      if (!filter.user_id && !filter.therapist_id && !filter.is_approved) {
        filter.is_approved = 2;
      }
    }

    const url = constructUrl('reviews', {
      filters: filter,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    localStorage.setItem('avg_rating', resp.average_rating);
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReviewList - reviews', 'error');
    return Promise.reject(err);
  }
};

const updateReview = async (_resource, params) => {
  const { id, payload } = params;
  const url = constructUrl(`therapist/review/${id}`, { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: payload,
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in updateReview - review', 'error');
    return Promise.reject(err);
  }
};

export { getReviewList, updateReview };
