import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Loading, Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { customDataProvider } from '../../data-provider';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import {
  fetchPreferences,
  updatePreferences,
} from '../../services/preferences';
import { BACK, FRONT, MASSAGE } from '../../utils/constants';
import { Details } from './details';
import { FocusPressureBack } from './focus-pressure-back';
import FocusPressureFront from './focus-pressure-front';
import { MassagePreferences } from './massage-preferences';

export const GuestPreferences = () => {
  let { userId } = useParams();
  const [user, setUser] = React.useState({});
  const [showPreference, setShowPreference] = React.useState(MASSAGE);

  React.useEffect(() => {
    customDataProvider.getOne('guest', { id: userId }).then(({ data }) => {
      setUser(data);
    });
  }, [userId]);

  const {
    aromaTherapy,
    lighting,
    temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
    userPreferences,
    loadingUserPreferences,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const detailsProps = {
    refetchUserPreferences,
    fetchPreferences,
    updatePreferences,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    userId,
    user,
  };

  const massagePreferencesProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    aromaTherapy,
    lighting,
    temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
    userId,
    user,
  };

  const focusPressureFrontProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    userId,
    step: '2',
    isFromSummary: true,
  };

  const focusPressureBackProps = {
    updatePreferences,
    refetchUserPreferences,
    setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    userId,
    step: '2',
    isFromSummary: true,
  };

  if (loadingUserPreferences) {
    return <Loading />;
  }

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Guest Details" />
      <CardContent>
        <>
          <div class="flex flex-row">
            <Details {...detailsProps} />
            {showPreference === MASSAGE && (
              <MassagePreferences {...massagePreferencesProps} />
            )}
            {showPreference === FRONT && (
              <FocusPressureFront
                className="w-1/2 m-4"
                {...focusPressureFrontProps}
              />
            )}
            {showPreference === BACK && (
              <FocusPressureBack
                className="w-1/2 m-4"
                {...focusPressureBackProps}
              />
            )}
          </div>
        </>
      </CardContent>
    </Card>
  );
};
