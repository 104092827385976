import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  SearchInput,
  SelectInput,
  TextField,
  useNotify,
  useRefresh
} from 'react-admin';
import Modal from 'react-modal';
import { customDataProvider } from '../data-provider';
import history from '../history';
import { formatPhoneNumber, getLocations } from '../utils';
import { TeamMembersTabs } from './tabs';

const MembersFilter = props => {
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
  const [workLocations, setWorkLocations] = useState([]);
  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  return (
    <Filter {...props} className="searchWrapper">
      <SearchInput source="name" placeholder="Name" alwaysOn />
      <SelectInput
        source="location_id"
        alwaysOn
        allowEmpty={false}
        choices={getLocations(workLocations)}
        className="select-no-label"
        initialValue={selectedLocation}
      />
    </Filter>
  );
};

export const TherapistsList = ({ ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const notify = useNotify();
  const refresh = useRefresh();

  const deactivateMember = id => {
    customDataProvider.update('updateTeamUser', { id, is_active: false }).then(
      () => {
        notify('Therapist Deactivated!');
        refresh();
      },
      err => {
        notify(err.response ? err.response?.data?.message : err.message);
        refresh();
      },
    );
  };
const Empty = () => (
  <div>
    <CreateButton />
  </div>
);

  const deactiveConfirmModal = () => {
    const header = 'Deactivate Therapist';
    const buttonText = 'Deactivate';
    const bodyContent =
      'Are you sure you want to deactivate this team member? They will lose access to Pat’s Portal & if they are a therapist they will be removed from the site/app.';
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          {bodyContent}
          <div className="py-5">
            <b>Name: </b>
            {userDetails?.first_name} {userDetails?.last_name}
          </div>
        </div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue button-red"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              onClick={() => {
                setShowModal(false);
                deactivateMember(userDetails?.user_id);
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const confirmModal = () => {
    setShowModal(true);
  };
  const onRowClick = id => {
    return history.push(`/employee/edit/${id}`);
  };

  return (
    <>
      {deactiveConfirmModal()}
      {<TeamMembersTabs />}
      <List
        title={'employee'}
        filters={<MembersFilter />}
        resource={'employee'}
        basePath={`/employee`}
        {...props}
        className="main-table"
        exporter={false}
        empty={<Empty />}
      >
        <>
          <Datagrid>
            <FunctionField
              label="Name"
              render={record =>
                record.last_name
                  ? `${record.first_name} ${record.last_name}`
                  : `${record.first_name}`
              }
            />
            <TextField source="email" label="Email" />
            <FunctionField
              label="Phone"
              source="phone"
              sortable={false}
              render={record => formatPhoneNumber(record.phone)}
            />
            <FunctionField
              label="Location"
              render={record =>
                record?.location?.name ? record?.location?.name : ''
              }
            />
            <FunctionField
              render={record => {
                return (
                  <div>
                    <button
                      className="button-blue button-red"
                      onClick={() => {
                        setUserDetails(record);
                        confirmModal();
                      }}
                    >
                      Deactivate
                    </button>
                  </div>
                );
              }}
            />
            <FunctionField
              render={record => {
                return (
                  <button
                    className="button-blue"
                    onClick={() => onRowClick(record?.id)}
                  >
                    View
                  </button>
                );
              }}
            />
          </Datagrid>
        </>
      </List>
    </>
  );
};
