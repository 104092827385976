import React, { useState } from 'react';
import {
  PasswordInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  required,
} from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import authProvider from '../auth-provider';
import { customDataProvider } from '../data-provider';
import Logo from '../assets/squeeze_logo.png';
import { showToast } from '../utils';
import history from '../history';

export const LoginPage = props => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [inputEmail, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const query = new URLSearchParams(history.location.search);
  const token = query.get('token');
  const validate = [required()];

  const submit = form => {
    const { username, password } = form;
    authProvider.login({ username, password });
  };

  const handleForgotPassword = () => {
    customDataProvider
      .update('forgotPassword', { email: inputEmail })
      .then(() => {
        setEmailSent(true);
      });
  };

  const handleResetPassword = form => {
    const { password, password_confirmation } = form;
    const payload = { password, password_confirmation, token };
    customDataProvider
      .update('password', {
        payload,
      })
      .then(() => {
        showToast('Password has been reset successfully! Please login.');
        setForgotPassword(false);
        setPasswordReset(true);
        history.push('/login');
      });
  };

  const goToLogin = () => {
    setForgotPassword(false);
    setEmailSent(false);
    history.push('/login');
  };

  const LoginToolbar = props => (
    <Toolbar {...props}>
      <SaveButton icon={<></>} label="Login" onSave={submit} />
    </Toolbar>
  );

  const loginContent = () => {
    return (
      <>
        <div className="page-wrap">
          <div className="login-container">
            <div className="logo-wrapper">
              <img src={Logo} alt="logo" />
            </div>
            <div className="login-wrap">
              <SimpleForm toolbar={<LoginToolbar />}>
                <TextInput
                  source="username"
                  label="Username"
                  validate={validate}
                />
                <PasswordInput
                  source="password"
                  label="Password"
                  validate={validate}
                />
              </SimpleForm>
              <div
                className="mt-4 cursor-pointer inline-block text-blue-600"
                onClick={() => setForgotPassword(true)}
              >
                Forgot password
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const ForgotPasswordToolbar = props => (
    <Toolbar {...props}>
      <SaveButton
        icon={<></>}
        label="Send Email"
        onSave={handleForgotPassword}
      />
    </Toolbar>
  );

  const forgotPasswordContent = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm toolbar={<ForgotPasswordToolbar />}>
              <TextInput
                source="inputEmail"
                label="Email"
                onChange={e => setEmail(e.target.value)}
                validate={validate}
              />
            </SimpleForm>
            <div
              className="mt-4 cursor-pointer inline-block text-blue-600"
              onClick={() => setForgotPassword(false)}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const ResetToolbar = props => (
    <Toolbar {...props}>
      <SaveButton
        icon={<></>}
        label="Reset Password"
        onSave={handleResetPassword}
      />
    </Toolbar>
  );
  const resetPasswordContent = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm toolbar={<ResetToolbar />}>
              <PasswordInput
                source="password"
                label="New Password"
                validate={validate}
              />
              <PasswordInput
                source="password_confirmation"
                label="Confirm Password"
                validate={validate}
              />
            </SimpleForm>
            <div
              className="mt-4 cursor-pointer inline-block text-blue-600"
              onClick={() => goToLogin()}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const emailSentContent = () => (
    <div className="page-wrap">
      <div className="login-container">
        <div className="logo-wrapper">
          <img src={Logo} alt="logo" />
        </div>
        <div className="login-wrap">
          <div className="text-center">
            <h1 className="text-xl">
              Password Reset Link has been sent. Check your Inbox!
            </h1>
            <div
              className="mt-4 cursor-pointer inline-block text-blue-600"
              onClick={() => goToLogin()}
            >
              Login Here
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MuiThemeProvider theme={props.theme}>
      {token && !passwordReset && resetPasswordContent()}
      {forgotPassword && !emailSent && forgotPasswordContent()}
      {(!forgotPassword || passwordReset) && loginContent()}
      {emailSent && !token && emailSentContent()}
    </MuiThemeProvider>
  );
};
