import React, { useEffect, useState } from 'react';
import {
  DateInput,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextInput,
  Toolbar
} from 'react-admin';
import '../appointment/appointment.css';
import '../user/user.css';
import {
  getFiltersDate,
  getLocations,
  isAdmin,
  isOperatingPartner
} from '../utils';
import CancelMembershipReport from './cancelMembership-report';
import DistributionReport from './distribution-report';
import GiftsReport from './gifts-report';
import HallSqueezersReport from './hall-squeezers-report';
import RatingsReport from './ratings-report';
import ScoreCardReport from './scorecard-report';
import TipsReport from './tips-report';
import { customDataProvider } from '../data-provider';

export const ReportsList = ({ permissions, ...props }) => {
  const [ratingFilter, setRatingFilter] = useState(getFiltersDate('ratings'));
  const [tipsFilter, setTipsFilter] = useState(getFiltersDate('tips'));
  const [hallFilter, setHallFilter] = useState({});
  const [cancelMemFilter, setCancelMemFilterFilter] = useState(
    getFiltersDate('cancelMember'),
  );
  const [giftFilter, setGiftFilter] = useState(getFiltersDate('gifts'));
  // const [promoFilter, setPromoFilter] = useState(getFiltersDate('promo'));
  const [scoreCardFilter, setScoreCardFilter] = useState(
    getFiltersDate('scoreCard'),
  );
  const [distributionFilter, setDistributionFilter] = useState(
    getFiltersDate('distribution'),
  );
  const [workLocations, setWorkLocations] = useState(null);

  const selectedLocation = localStorage.getItem('selectedLocation');
  const filters = selectedLocation ? { location_id: selectedLocation } : '';

  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  const clear = () => {
    setRatingFilter({});
    setTipsFilter({});
    setHallFilter({});
    setCancelMemFilterFilter({});
    setGiftFilter({});
    // setPromoFilter({});
    setScoreCardFilter({});
    setDistributionFilter({});
  };
  const SearchToolBar = props => {
    return (
      <Toolbar {...props}>
        <SaveButton label="Search" />
        <div className="button-blue" style={{ marginLeft: '10px' }}>
          <button onClick={clear}>Clear</button>
        </div>
      </Toolbar>
    );
  };

  // rating search
  const handleRatingChange = form => {
    const { name, dateLower, dateUpper, location_id } = form;
    const filters = {
      name: name,
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setRatingFilter({ ...filters });
  };

  const RatingFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: ratingFilter.name,
          dateLower: ratingFilter.dateLower,
          dateUpper: ratingFilter.dateUpper,
          location_id: ratingFilter.location_id || filters.location_id,
        }}
        save={handleRatingChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <DateInput source="dateLower" label="Rating Date >=" />
          <DateInput source="dateUpper" label="Rating Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  // Tips Search
  const handleTipsChange = form => {
    const { name, dateUpper, dateLower, location_id } = form;
    const filters = {
      name: name,
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setTipsFilter({ ...filters });
  };
  const TipsFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: tipsFilter.name,
          dateLower: tipsFilter.dateLower,
          dateUpper: tipsFilter.dateUpper,
          location_id: tipsFilter.location_id || filters.location_id,
        }}
        save={handleTipsChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <DateInput source="dateLower" label="Tip Date >=" />
          <DateInput source="dateUpper" label="Tip Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //Hall squeezers Filter
  const handleHallChange = form => {
    const { name, countUpper, countLower, location_id } = form;
    const filters = {
      name: name,
      countLower: countLower,
      countUpper: countUpper,
      location_id: location_id,
    };
    setHallFilter({ ...filters });
  };
  const HallSqueezerFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: hallFilter.name,
          countLower: hallFilter.countLower,
          countUpper: hallFilter.countUpper,
          location_id: hallFilter.location_id || filters.location_id,
        }}
        save={handleHallChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <NumberInput source="countLower" label="Completed Appointments >=" />
          <NumberInput source="countUpper" label="Completed Ammointments <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //CancelledMembers Filter
  const handleCancelMembersChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setCancelMemFilterFilter({ ...filters });
  };
  const CancelledMembersFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: cancelMemFilter.dateLower,
          dateUpper: cancelMemFilter.dateUpper,
        }}
        save={handleCancelMembersChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Cancellation Date >=" />
          <DateInput source="dateUpper" label="Cancellation Date <=" />
        </div>
      </SimpleForm>
    );
  };

  //Gift search
  const handleGiftReportChange = form => {
    const {
      purchaseDateLower,
      purchaseDateUpper,
      redeemedAtLower,
      redeemedAtUpper,
      location_id,
    } = form;
    const filters = {
      purchaseDateLower: purchaseDateLower,
      purchaseDateUpper: purchaseDateUpper,
      redeemedAtLower: redeemedAtLower,
      redeemedAtUpper: redeemedAtUpper,
      location_id: location_id,
    };
    setGiftFilter({ ...filters });
  };

  const GiftFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          purchaseDateLower: giftFilter.purchaseDateLower,
          purchaseDateUpper: giftFilter.purchaseDateUpper,
          redeemedAtLower: giftFilter.redeemedAtLower,
          redeemedAtUpper: giftFilter.redeemedAtUpper,
          location_id: giftFilter.location_id || filters.location_id,
        }}
        save={handleGiftReportChange}
      >
        <div className="filters-flex">
          <DateInput source="purchaseDateLower" label="Gift Purchase Date >=" />
          <DateInput source="purchaseDateUpper" label="Gift Purchase Date <=" />
          <DateInput source="redeemedAtLower" label="RedeemedAt Date >=" />
          <DateInput source="redeemedAtUpper" label="RedeemedAt Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={[
                { id: 'all', name: 'All' },
                ...getLocations(workLocations),
              ]}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  // Promo Search
  // const handlePromoChange = form => {
  //   const { txnDateLower, txnDateUpper, location_id } = form;
  //   const filters = {
  //     txnDateLower: txnDateLower,
  //     txnDateUpper: txnDateUpper,
  //     location_id: location_id,
  //   };
  //   setPromoFilter({ ...filters });
  // };

  // const PromoFilters = () => {
  //   return (
  //     <SimpleForm
  //       className="styled-form2 filters-form"
  //       toolbar={<SearchToolBar />}
  //       initialValues={{
  //         txnDateLower: promoFilter.txnDateLower,
  //         txnDateUpper: promoFilter.txnDateUpper,
  //         location_id: promoFilter.location_id || filters.location_id,
  //       }}
  //       save={handlePromoChange}
  //     >
  //       <div className="filters-flex">
  //         <DateInput source="txnDateLower" label="Promo Date >=" />
  //         <DateInput source="txnDateUpper" label="Promo Date <=" />
  //         {workLocations && (
  //           <SelectInput
  //             source="location_id"
  //             optionText="name"
  //             choices={getLocations(workLocations)}
  //             label="Choose Location"
  //           />
  //         )}
  //       </div>
  //     </SimpleForm>
  //   );
  // };

  // ScoreCard Search
  const handleScoreCardChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setScoreCardFilter({ ...filters });
  };

  const ScoreCardFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: scoreCardFilter.dateLower,
          dateUpper: scoreCardFilter.dateUpper,
          location_id: scoreCardFilter.location_id || filters.location_id,
        }}
        save={handleScoreCardChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  const handleDistributionChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setDistributionFilter({ ...filters });
  };

  const DistributionFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: distributionFilter.dateLower,
          dateUpper: distributionFilter.dateUpper,
          location_id: distributionFilter.location_id || filters.location_id,
        }}
        save={handleDistributionChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  return (
    <>
      <div>
        <TabbedShowLayout className="max60vh">
          <Tab label="Therapist Ratings">
            <RatingFilters />
            <RatingsReport filters={filters} otherFilters={ratingFilter} />
          </Tab>
          <Tab label="Therapist Tips">
            <TipsFilters />
            <TipsReport filters={filters} otherFilters={tipsFilter} />
          </Tab>
          <Tab label="Hall of Squeezers">
            <HallSqueezerFilters />
            <HallSqueezersReport filters={filters} otherFilters={hallFilter} />
          </Tab>
          {isAdmin(permissions) && (
            <Tab label="Cancelled Members">
              <CancelledMembersFilters />
              <CancelMembershipReport
                filters={filters}
                otherFilters={cancelMemFilter}
              />
            </Tab>
          )}
          {isAdmin(permissions) && (
            <Tab label="Gifts">
              <GiftFilters />
              <GiftsReport filters={filters} otherFilters={giftFilter} />
            </Tab>
          )}
          {(isAdmin(permissions) || isOperatingPartner(permissions)) && (
            <Tab label="KPI Scorecard">
              <ScoreCardFilters />
              <ScoreCardReport
                filters={filters}
                otherFilters={scoreCardFilter}
              />
            </Tab>
          )}
          {isAdmin(permissions) && (
            <Tab label="Distribution Report">
              <DistributionFilters />
              <DistributionReport
                filters={filters}
                otherFilters={distributionFilter}
              />
            </Tab>
          )}
        </TabbedShowLayout>
      </div>
    </>
  );
};
