import Card from '@material-ui/core/Card';
import { DELIVERY_MODE } from '../utils/constants';
import { getFormattedDate_T } from '../utils';

export const GiftCardRecipient = ({ giftDetails }) => {
  const deliveryDetailsArray = giftDetails?.giftcarddeliveries;
  const deliveryDetails = deliveryDetailsArray?.length
    ? deliveryDetailsArray[0]
    : {};

  const rowItem = (label, value) => {
    return (
      <>
        <div className="label">{label}</div>
        <div>{value}</div>
      </>
    );
  };
  return (
    <Card className="p-4">
      <div className="m-2 text-lg font-bold">Recipient</div>
      <div className="details-display align-center">
        {rowItem('Recipient Name :', deliveryDetails?.recipient_name || '')}
        {rowItem('Recipient Email :', deliveryDetails?.recipient_email || '')}
        {rowItem(
          'Recipient Address :',
          `${deliveryDetails?.street1 || ''} ${
            deliveryDetails?.street2 || ''
          }${' '}${deliveryDetails?.state || ''}`,
        )}
        {rowItem('Delivery Type :', deliveryDetails?.delivery_mode)}
        {deliveryDetails.delivery_mode === DELIVERY_MODE.SHIP
          ? rowItem(
              'Shipped Date :',
              deliveryDetails?.delivery_date
                ? getFormattedDate_T(deliveryDetails?.delivery_date)
                : '',
            )
          : rowItem(
              'Email Delivery Date :',
              deliveryDetails?.delivery_date
                ? getFormattedDate_T(deliveryDetails?.delivery_date)
                : '',
            )}
        {rowItem('Gift Message :', deliveryDetails?.message)}
      </div>
    </Card>
  );
};
