import axios from 'axios';
import _ from 'lodash';
import authProvider from '../auth-provider';
import { getToken } from './auth';
const API_URL = process.env.REACT_APP_API_BASE;

/**
 * Handle Network Requests.
 * @param {string} endpoint - Api path.
 * @param {object} [config={}] - Config object.
 * @param {string} config.method - Method.
 * @param {object} config.data - Body for POST calls.
 * @param {string} config.token - Token for authenticated calls.
 * @param {object} config.headers - Additional headers
 */

const client = async (
  endpoint,
  {
    data,
    headers,
    method,
    transform = false,
    authNeeded = false,
    ...rest
  } = {},
) => {
  const config = {
    url: `${API_URL}/${endpoint}`,
    method: method || (data ? 'POST' : 'GET'),
    data: data ? JSON.stringify(data) : undefined,
    headers: _.pickBy({
      Authorization: authNeeded ? getToken() : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...headers,
    }),
    transformResponse: [].concat(axios.defaults.transformResponse, resp => {
      if (transform && resp.items) {
        return resp.items;
      }
      return resp;
    }),
    ...rest,
  };

  try {
    const response = await axios(config);
    const { data: resData } = response;

    return resData;
  } catch (error) {
    authProvider.checkError(error);
    return Promise.reject(error?.response?.data);
  }
};

export { client };
