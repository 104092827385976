/* eslint-disable import/no-anonymous-default-export */
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getUserMembership = async (_resource, params) => {
  try {
    const { user_id } = params;

    const url = constructUrl(`membership?user_id=${user_id}`, {
      pagination: false,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp,
    };
  } catch (err) {
    showToast('Error in get Membership - user', 'error');
    return Promise.reject(err);
  }
};

const updateCancelMembership = async (_resource, params) => {
  const url = constructUrl('membership', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: { ...params },
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in cancel Membership - membership', 'error');
    return Promise.reject(err);
  }
};
const updateMembershipResumeDate = async (_resource, params) => {
  const { membership_id, resume_date } = params;
  const url = constructUrl(`membership/${membership_id}`, {
    pagination: false,
  });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: { resume_at: resume_date },
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in update membership resume date - membership', 'error');
    return Promise.reject(err);
  }
};

export {
  getUserMembership,
  updateCancelMembership,
  updateMembershipResumeDate,
};
