import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useQuery,
  Pagination,
  FunctionField,
} from 'react-admin';
import { keyBy } from 'lodash';
import { GUESTS } from '../utils/constants';
import { getFormattedDateTime, getStarRatings } from '../utils';
import { useParams } from 'react-router-dom';

export const UserReviews = ({ card, ...props }) => {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const { therapistId } = useParams();

  const filters =
    card === GUESTS ? { user_id: props.id } : { therapist_id: therapistId };
  const { data, total } = useQuery({
    type: 'getList',
    resource: 'review',
    payload: {
      pagination: { page, perPage },
      filter: filters,
    },
  });
  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );
  return (
    <ListContextProvider
      value={{
        resource: 'review',
        basePath: `/guest/${props.id}`,
        data: keyBy(data, 'id'),
        ids: data && data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
        total,
        page,
        perPage,
        setPerPage,
        setPage,
      }}
      perPage={perPage}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          label="Service Date/time"
          render={record =>
            getFormattedDateTime(
              record?.appointment?.start_at,
              record?.appointment?.timezone,
            )
          }
        />
        {card && card === 'team_members' ? (
          <TextField source="user.first_name" label="Guest" />
        ) : (
          <TextField source="therapist.first_name" label="Therapist" />
        )}

        <FunctionField
          label="Status"
          render={record =>
            record?.is_approved
              ? record.is_approved === 1
                ? 'Approved'
                : 'Pending'
              : 'Rejected'
          }
        />
        <FunctionField
          label="Visible to public?"
          render={record => (record.share_to_public ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Visible to Therapist?"
          render={record => (record.share_to_therapist ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Rating"
          render={record => getStarRatings(record?.rating)}
        />
        <TextField label="Review" source="comment" />
      </Datagrid>
      <PostPagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
      />
    </ListContextProvider>
  );
};
