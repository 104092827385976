import CloseIcon from '@material-ui/icons/Close';
import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Datagrid, FunctionField,
  ListContextProvider,
  Pagination, SelectInput, SimpleForm, TextField, TextInput, Toolbar, useNotify, useQuery, useRefresh
} from 'react-admin';
import Modal from 'react-modal';
import { useDebouncedCallback } from 'use-debounce';
import { customDataProvider } from '../data-provider';
import history from '../history';
import { formatPhoneNumber, getLocations } from '../utils';
import { TeamMembersTabs } from './tabs';

export const OtherStaffList = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [otherStaffFilter, setOtherStaffFilter] = useState({});
  const [workLocations, setWorkLocations] = useState([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));

  const { data, total } = useQuery({
    type: 'getList',
    resource: 'otherStaff',
    payload: {
      filter: {
        category: 'other_staff',
        name: otherStaffFilter.name,
        location_id: otherStaffFilter.location_id
          ? otherStaffFilter.location_id
          : selectedLocation,
      },
      pagination: { page, perPage },
    },
  });

  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  const deactivateMember = id => {
    customDataProvider.update('updateTeamUser', { id, is_active: false }).then(
      () => {
        notify('Account deactivated!');
        refresh();
      },
      err => {
        notify(
          err.response ? err.response?.data?.message : err.message,
          'error',
        );
        refresh();
      },
    );
  };
  const deactiveConfirmModal = () => {
    const header = 'Deactivate Account';
    const buttonText = 'Deactivate';
    const bodyContent =
      'Are you sure you want to deactivate this team member? They will lose access to Pat’s Portal & if they are a therapist they will be removed from the site/app.';
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          {bodyContent}
          <div className="py-5">
            <b>Name: </b>
            {userDetails?.first_name} {userDetails?.last_name}
          </div>
        </div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue button-red"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              onClick={() => {
                setShowModal(false);
                deactivateMember(userDetails?.id);
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const confirmModal = () => {
    setShowModal(true);
  };
  const onRowClick = id => {
    return history.push(`/employee/other-staff/${id}`);
  };

  //filters
  const handleChange = useDebouncedCallback(e => {
    const { name, value } = e;
    const filters = {
      name: name === 'name' ? value : otherStaffFilter.name,
      location_id:
        name === 'location_id' ? value : otherStaffFilter.location_id,
    };
    setOtherStaffFilter({ ...filters });
  }, 300);

  const OtherStaffFilters = () => {
    return (
      <SimpleForm
        className="styled-form2"
        toolbar={<Toolbar style={{ display: 'none' }} />}
        initialValues={{
          name: otherStaffFilter.name,
          location_id: otherStaffFilter.location_id
            ? otherStaffFilter.location_id
            : selectedLocation,
        }}
      >
        <div className="grid-3">
          <TextInput
            source="name"
            label="Name"
            onChange={e => handleChange(e.target)}
          />
          <SelectInput
            source="location_id"
            alwaysOn
            choices={getLocations(workLocations)}
            className="select-no-label"
            onChange={e => handleChange(e.target)}
          />
        </div>
      </SimpleForm>
    );
  };

  return (
    <>
      {deactiveConfirmModal()}
      <TeamMembersTabs selectedTab={'otherStaff'} />
      <OtherStaffFilters />
      <ListContextProvider
        value={{
          resource: 'employee',
          basePath: `/employee`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        className="main-table"
      >
        <Datagrid>
          <FunctionField
            label="Name"
            render={record =>
              record.last_name
                ? `${record.first_name} ${record.last_name}`
                : `${record.first_name}`
            }
          />
          <TextField source="email" label="Email" />
          <FunctionField
            label="Phone"
            source="phone"
            sortable={false}
            render={record => formatPhoneNumber(record.phone)}
          />
          <FunctionField
            label="Location"
            render={record =>
              record?.location?.name ? record?.location?.name : ''
            }
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue button-red"
                  onClick={() => {
                    setUserDetails(record);
                    confirmModal();
                  }}
                >
                  Deactivate
                </button>
              );
            }}
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => onRowClick(record?.id)}
                >
                  View
                </button>
              );
            }}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
