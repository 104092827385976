import React from 'react';
import { UserMenu, MenuItemLink, useAuthState } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { useProfile } from '../profile';

export const MyUserMenu = props => {
  const { authenticated } = useAuthState();

  return authenticated ? <AuthenticatedUserMenu {...props} /> : null;
};

const AuthenticatedUserMenu = props => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/profile"
        primaryText="Profile"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
};
