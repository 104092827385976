import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useQuery,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  required,
  useNotify,
  useRefresh,
  SelectInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TeamMembersTabs } from './tabs';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import { USER_ROLES, USER_ROLES_PERMISSION } from '../utils/constants';
import { customDataProvider } from '../data-provider';
import {
  showToast,
  getLocations,
  getEmployeesLocationName,
  getRoles,
} from '../utils';

export const OtherStaffView = ({ ...props }) => {
  const { staffId } = useParams();
  const [showModal, setShowModal] = React.useState(false);
  const [workLocations, setWorkLocations] = useState([]);
  const validateRequired = [required()];
  const refresh = useRefresh();
  const notify = useNotify();
  const userRole = localStorage.getItem('role');

  const { data } = useQuery({
    type: 'getList',
    resource: 'otherStaff',
    payload: {
      filter: { category: 'other_staff', id: staffId },
    },
  });

  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  const staffData = data && data[0];

  const handleSave = form => {
    const { first_name, last_name, email, phone, userLocation, role } = form;
    const locations = _.filter(userLocation, Boolean);
    let body = {
      id: staffData?.id,
      first_name,
      last_name,
      email,
      // location_id: locations.join(', '),
      locations,
      role,
    };
    if (phone) {
      body.phone = phone;
    }
    customDataProvider.update('user', body).then(
      ({ data }) => {
        if (data.id) {
          setShowModal(false);
          showToast('User updated successfully!');
          refresh();
        }
      },
      err => {
        notify(err.response ? err.response?.data?.message : err.message);
        refresh();
      },
    );
  };

  const getUserLocation = location => {
    const _dtat = location.map(a => {
      return a.location_id;
    });
    return _dtat;
  };

  const getRole = role => {
    const data = USER_ROLES.find(u => u.id === role);
    return data?.name;
  };

  const profileModal = () => {
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">Edit Account</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <SimpleForm
          save={handleSave}
          initialValues={{
            first_name: staffData?.first_name,
            last_name: staffData?.last_name,
            email: staffData?.email,
            phone: staffData?.phone,
            userLocation: getUserLocation(staffData?.userLocation),
            role: staffData?.role,
            // is_active: 'active',
          }}
        >
          <TextInput source="first_name" label="First Name" />
          <TextInput source="last_name" label="Last Name" />
          <TextInput source="email" label="Email" />
          <TextInput source="phone" label="Phone" />
          <SelectInput
            source="role"
            optionText="name"
            choices={getRoles(userRole, USER_ROLES)}
            label="Role"
            validate={validateRequired}
          />
          <CheckboxGroupInput
            label="Locations"
            source="userLocation"
            validate={validateRequired}
            choices={getLocations(workLocations)}
          />
        </SimpleForm>
      </Modal>
    );
  };

  const isPermissionAllowed = staffRole => {
    if (staffRole && USER_ROLES_PERMISSION) {
      const currentUserRoles = USER_ROLES_PERMISSION[userRole];
      return currentUserRoles.includes(staffRole);
    }
    return '';
  };

  const render = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      userLocation,
      role,
    } = staffData;
    return (
      <>
        <div>
          {showModal && profileModal()}
          {<TeamMembersTabs selectedTab={'otherStaff'} />}
          <h1 className="text-xl mt-6 font-bold">VIEW ACCOUNT DETAILS</h1>
          <br />
          <div className="details-display">
            <div className="label">Name: </div>
            <div>{`${first_name} ${last_name}`}</div>
            <div className="label">Email: </div>
            <div>{email}</div>
            <div className="label">Phone Number: </div>
            <div>{phone}</div>
            <div className="label">Employee Location(s): </div>
            <div>{getEmployeesLocationName(userLocation)}</div>
            <div className="label">Role: </div>
            <div>{getRole(role)}</div>
          </div>
          <br />
          <div>
            {isPermissionAllowed(role) && (
              <button
                className="button-blue"
                onClick={() => setShowModal(true)}
              >
                Edit Staff Profile
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  return data ? render() : <></>;
};
