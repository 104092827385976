import React, { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import { customDataProvider } from '../data-provider';
import { useRefresh, useQuery } from 'react-admin';
import Card from '@material-ui/core/Card';
import { getFormattedCurrency, getFormattedDate_T } from '../utils';
import moment from 'moment';
import { isAdmin, isGss } from '../utils';
import { capitalize } from 'lodash';

export const GiftCardInfo = ({ permissions, giftDetails }) => {
  const refresh = useRefresh();
  const [showModal, setShowModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  // const deliveryDetailsArray = giftDetails?.giftcarddeliveries;
  // const deliveryDetails = deliveryDetailsArray?.length
  //   ? deliveryDetailsArray[0]
  //   : {};
  const role = localStorage.getItem('role');
  const userLocation = JSON.parse(localStorage.getItem('userWorkLocations'));

  const rowItem = (label, value) => {
    return (
      <>
        <div className="label">{label}</div>
        <div>{value}</div>
      </>
    );
  };

  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'locations',
    payload: {},
  });

  const handleRefundGift = () => {
    const giftCode = giftDetails?.gift_code;
    customDataProvider.update('refund', { code: giftCode }).then(() => {
      setShowModal(false);
      refresh();
    });
  };

  const handleRedeemGift = () => {
    const giftCode = giftDetails?.gift_code;
    customDataProvider
      .update('redeemGift', {
        id: giftDetails?.id,
        status: 'redeemed',
        location_id: selectedLocation,
      })
      .then(() => {
        setShowRedeemModal(false);
        refresh();
      });
  };

  const refundModal = () => {
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">Process a Refund</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          Are you sure you want to process a refund for this gift card purchase?
        </div>
        <div className="details-display btn-padding">
          <div>
            <button className="button-blue" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
          <div align="right">
            <button className="button-blue" onClick={() => handleRefundGift()}>
              Refund
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const redeemModal = () => {
    return (
      <Modal isOpen={showRedeemModal}>
        <div className="modal-header">
          <div className="light-text">Redeem Card</div>
          <CloseIcon
            onClick={() => setShowRedeemModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          Where was this gift card redeemed?
          <br />
          <br />
          <div>
            <select
              className="border-customized-input"
              name="members"
              id="members"
              onChange={e => setSelectedLocation(e.target.value)}
              defaultValue="Select"
            >
              <option value="Select" disabled>
                Select
              </option>
              {userLocation &&
                userLocation.map(t => (
                  <option value={t.location_id}>{t.location.name}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue"
              onClick={() => setShowRedeemModal(false)}
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              disabled={!selectedLocation}
              onClick={() => handleRedeemGift()}
            >
              Redeem Gift Card
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const getLocationName = locationId => {
    const location = locations && locations.find(loc => loc.id === locationId);
    return location && location.name;
  };

  return (
    <>
      {showModal && refundModal()}
      {showRedeemModal && redeemModal()}
      <Card className="p-4">
        <div className="m-2 text-lg font-bold">Gift Card</div>
        <div className="details-display align-center">
          {rowItem('Gift Card Code :', giftDetails?.gift_code)}
          {rowItem('Status :', capitalize(giftDetails?.gift_status))}
          {rowItem(
            'Gift Card Value :',
            getFormattedCurrency(giftDetails?.gift_card_value),
          )}
          {rowItem(
            'Amount Charged :',
            getFormattedCurrency(giftDetails?.charge_amount),
          )}
          {rowItem(
            'Redeemed at :',
            giftDetails?.location_id && giftDetails?.gift_status === 'redeemed'
              ? getLocationName(giftDetails?.location_id)
              : '',
          )}
          {rowItem(
            'Redeemed Date :',
            giftDetails?.redeemed_at
              ? getFormattedDate_T(giftDetails?.redeemed_at)
              : '',
          )}
          {rowItem(
            'Redeemed by :',
            (giftDetails?.redeemed_at && giftDetails?.redeemedby?.email) || '',
          )}
          {['paid', 'emailed', 'shipped'].includes(
            giftDetails?.gift_status,
          ) && (
            <div className="flex">
              {/* <LocalAtmIcon /> */}
              <div className="blueLink" onClick={() => setShowModal(true)}>
                Refund
              </div>
              {(role === 'admin' || role === 'gss') && (
                <div
                  className="blueLink"
                  onClick={() => setShowRedeemModal(true)}
                >
                  Redeem Card
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </>
  );
};
