const config = {
  apiBase: process.env.REACT_APP_API_BASE,
  brentwood: process.env.REACT_APP_LOCATION_ID_BRENTWOOD,
  studiocity: process.env.REACT_APP_LOCATION_ID_STUDIO_CITY,
  scottsdale: process.env.REACT_APP_LOCATION_ID_SCOTTSDALE,
  cherrycreek: process.env.REACT_APP_LOCATION_ID_CHERRYCREEK,
  alamoheights: process.env.REACT_APP_LOCATION_ID_ALAMOHEIGHTS,
  rogers: process.env.REACT_APP_LOCATION_ID_ROGERS,
};

export default config;
