import { getHeardUsOtherValue, getHeardUsValue, showToast } from '../utils';
import { constructUrl } from '../utils/api';
const { client } = require('../services/api-client');

const getUserList = async (_resource, params) => {
  try {
    let { filter, pagination } = params;
    let queryParams;
    filter.role = 'user';
    const url = constructUrl('users', {
      queryParams: queryParams,
      filters: filter,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getUserList - user', 'error');
    return Promise.reject(err);
  }
};

const getUser = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id, ...params.filter };

    const url = constructUrl('users', { filters, pagination: false });
    const resp = await client(url, { authNeeded: true });
    let userResp = resp.items[0];
    if (userResp) {
      userResp.heardAbout = getHeardUsValue(userResp);
      userResp.heardAboutOther = getHeardUsOtherValue(userResp);
    }
    return {
      data: userResp,
    };
  } catch (err) {
    showToast('Error in getUser - user', 'error');
    return Promise.reject(err);
  }
};

const getUserProfile = async (_resource, params) => {
  try {
    const user = localStorage.getItem('user');
    return {
      data: user,
    };
  } catch (err) {
    showToast('Error in getUser - user', 'error');
    return Promise.reject(err);
  }
};

const updateUser = async (_resource, params) => {
  const {
    id,
    first_name,
    last_name,
    phone,
    dob,
    heard_about_us,
    vi_squeeze,
    guest_notes,
    yelp_reviewer,
    google_reviewer,
  } = params.data;
  const url = constructUrl('user', { pagination: false });
  let data = {
    id,
    first_name,
    last_name,
    phone,
    dob,
    vi_squeeze: vi_squeeze ? true : false,
    guest_notes,
    yelp_reviewer: yelp_reviewer ? true : false,
    google_reviewer: google_reviewer ? true : false,
  };

  if (heard_about_us) {
    data.heard_about_us = heard_about_us;
  }
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data,
    });
    return { data: resp };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const updateUserProfile = async (_resource, params) => {
  const { data } = params;
  const { email, first_name, last_name } = data;
  const payload = { email, first_name, last_name };
  const url = constructUrl('user', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: payload,
    });
    return { data: resp };
  } catch (err) {
    showToast(`Error in updating user!`, 'error');
    return Promise.reject(err);
  }
};

const updateMembershipType = async (_resource, params) => {
  const { applyFoundingMembership, membershipId } = params;
  const url = constructUrl(`membership/${membershipId}`, { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: {
        apply_founding_membership: applyFoundingMembership,
      },
    });
    return { data: resp };
  } catch (err) {
    showToast(`Error in updating membership type!`, 'error');
    return Promise.reject(err);
  }
};

const getUserPreferences = async (_resource, params) => {
  try {
    const { id } = params;
    // const filters = { user_id: id };
    let url = constructUrl(`user/preferences`, {
      queryParams: `user_id=${id}`,
      pagination: false,
      // filters,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getUserPreferences - user', 'error');
    return Promise.reject(err);
  }
};

const updateUserWallet = async (_resource, params) => {
  const { payload, id } = params;
  const { amount, reason, otherReason } = payload;
  const updateReason = reason === 'Other' ? otherReason : reason;
  const url = constructUrl(`user/${id}/wallet`, { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: { amount, reason: updateReason },
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in updateWallet - user', 'error');
    return Promise.reject(err);
  }
};

const getWalletTransactions = async (_resource, params) => {
  const { filter, pagination } = params;
  if (filter) {
    {
      filter.fields = `*,updatedBy.*`;
    }
  }
  try {
    let url = constructUrl(`user/wallet`, { filters: filter, pagination });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getUserTransactions - user', 'error');
    return Promise.reject(err);
  }
};

const getTransactionsHistory = async (_resource, params) => {
  const { filters, pagination } = params;
  try {
    let url = constructUrl(`transactions`, {
      filters,
      pagination,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getUserTransactions - user', 'error');
    return Promise.reject(err);
  }
};

const transactionsRefund = async (_resource, params) => {
  try {
    const { payload, id } = params;
    const url = constructUrl(`transaction/${id}/refund`, { pagination: false });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: payload,
    });
    return {
      data: resp,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const getLocations = async (_resource, params) => {
  try {
    let url = constructUrl(`locations`, { limit: 50 });
    const resp = await client(url, { authNeeded: false });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getLocations - user', 'error');
    return Promise.reject(err);
  }
};

const getUserWorkLocations = async (_resource, params) => {
  try {
    let url = constructUrl(`userlocations`, { limit: 50 });
    const resp = await client(url, { authNeeded: true });
    const sortedWorkLocations = resp?.items.sort((a, b) => a?.location?.name.localeCompare(b?.location?.name));
    return {
      data: sortedWorkLocations,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getUserWorkLocations - user', 'error');
    return Promise.reject(err);
  }
};

const getServices = async (_resource, params) => {
  const { location_id } = params;
  try {
    let url = constructUrl(`location/${location_id}/services`, {});
    const resp = await client(url, { authNeeded: false });
    return {
      data: resp.services,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getServices - services', 'error');
    return Promise.reject(err);
  }
};

// Edit user
const updateUserInfo = async (_resource, data) => {
  const url = constructUrl('user', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data,
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in updateUser - user', 'error');
    return Promise.reject(err);
  }
};

const resetPassword = async (_resource, params) => {
  const { payload } = params;
  const url = constructUrl('reset-password', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: payload,
    });
    return { data: resp };
  } catch (err) {
    const genericMessage = 'Error in password reset!';
    const message = err ? err.message : genericMessage;
    const messageArr = message !== genericMessage ? message.split(',').join('\n') : genericMessage;
    showToast(`${messageArr}`, 'error');
    return Promise.reject(err);
  }
};

const forgotPassword = async (_resource, params) => {
  const { email } = params;
  const url = constructUrl('forgot-password', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: { email, source: 'admin' },
    });
    return { data: resp };
  } catch (err) {
    console.log(`err!!!`, err);
    showToast(`Error in forgot password! `, 'error');
    return Promise.reject(err);
  }
};

export {
  updateMembershipType,
  getUserList,
  getUser,
  updateUser,
  getUserPreferences,
  updateUserWallet,
  getWalletTransactions,
  getLocations,
  getUserWorkLocations,
  getTransactionsHistory,
  transactionsRefund,
  getServices,
  updateUserInfo,
  updateUserProfile,
  getUserProfile,
  resetPassword,
  forgotPassword,
};
