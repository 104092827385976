import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useQuery,
  Pagination,
  FunctionField,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';
import { GUESTS } from '../utils/constants';
import { getUtcToLocalDateTime, getFormattedCurrency } from '../utils';

export const UserTransactions = ({ card, ...props }) => {
  const filter =
    card === GUESTS
      ? { filters: `user_id=${props.id}` }
      : { filters: `therapist_id=${props.id}` };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'walletTransactions',
    payload: { pagination: { page, perPage }, filter },
  });
  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  return (
    <ListContextProvider
      value={{
        resource: 'walletTransactions',
        basePath: `/guest/${props.id}`,
        data: keyBy(data, 'id'),
        ids: data && data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
        total,
        page,
        perPage,
        setPerPage,
        setPage,
      }}
      // pagination={<PostPagination />}
      perPage={perPage}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          label="Date"
          render={record => getUtcToLocalDateTime(record.created_at)}
        />
        <FunctionField
          label="Amount"
          render={record => getFormattedCurrency(record?.amount)}
        />
        <FunctionField
          label="Action"
          render={record => (record.amount > 0 ? 'Add' : 'Deduct')}
        />
        <FunctionField
          label="Added By"
          render={record =>
            record?.updatedBy
              ? `${record?.updatedBy.first_name} ${record?.updatedBy.last_name}`
              : ''
          }
        />
        <FunctionField
          label="Reason"
          render={record =>
            record.entity_type === 'appointment' && !record.reason
              ? 'Appointment'
              : record.reason
          }
        />{' '}
      </Datagrid>
      <PostPagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
      />
    </ListContextProvider>
  );
};
