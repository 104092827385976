import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Loading, Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { updatePreferences } from '../../services/preferences';
import { FocusPressureBack } from './focus-pressure-back';

export const GuestBackPreference = () => {
  let { userId } = useParams();

  const {
    userPreferences,
    loadingUserPreferences,
    // userPreferencesError,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const focusPressureBackProps = {
    updatePreferences,
    refetchUserPreferences,
    // setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    // history,
    userId,
    step: '2',
    // resetUpdatedPreferences,
    isFromSummary: true,
  };

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Back Preferences" />
      {loadingUserPreferences && <Loading />}
      {!loadingUserPreferences && <CardContent>
        <div class="flex-X flexrow-X">
          <FocusPressureBack className="m-4" {...focusPressureBackProps} />
        </div>
      </CardContent>}
    </Card>
  );
};
