import {
  appointmentRefund, getAppointmenByDatetList,
  getAppointment, getAppointmentList, updateAppointment
} from './appointment/appointment-queries';
import {
  createMember, getInactiveUsers, getOtherStaffList, getTherapist, getTherapistsList, updateTeamMembers,
  updateTeamUser
} from './employee/queries';
import {
  getGift,
  getGiftList,
  getShipQueueList, purchaseGift,
  redeemGift, updateGift
} from './gift/gift-queries';
import {
  getUserMembership,
  updateCancelMembership,
  updateMembershipResumeDate
} from './membership/membership-queries';
import {
  createPromo,
  getPromo, getPromoList, updatePromo
} from './promo/promo-queries';
import {
  getCancelMembershipReportList, getDistributionReport, getGiftsReport, getHallSqueezersReport, getPromoRevenueReport, getScoreboardReport, getSqueezeBucksRevenueReport, getTherapistsRatingReport, getTipsReport
} from './reports/report-queries';
import { getReviewList, updateReview } from './reviews/review-queries';
import {
  getScheduleList, getTherapistList
} from './therapist/therapist-queries';
import { getTipList } from './tips/tip-queries';
import {
  forgotPassword, getLocations, getUserWorkLocations, getServices, getTransactionsHistory, getUser, getUserList, getUserPreferences, getUserProfile, getWalletTransactions, resetPassword, transactionsRefund, updateUser, updateUserInfo,
  updateUserProfile, updateUserWallet, updateMembershipType
} from './user/user-queries';
import { createWaiver, getWaiver } from './waiver/queries';

export const customDataProvider = {
  getList: (resource, params) => {
    if (resource === 'guest') {
      return getUserList(resource, params);
    }
    if (resource === 'employee') {
      return getTherapistsList(resource, params);
    }
    if (resource === 'appointment') {
      return getAppointmentList(resource, params);
    }
    if (resource === 'appointmentByDate') {
      return getAppointmenByDatetList(resource, params);
    }
    if (resource === 'therapist') {
      return getTherapistList(resource, params);
    }
    if (resource === 'review') {
      return getReviewList(resource, params);
    }
    if (resource === 'tip') {
      return getTipList(resource, params);
    }
    if (resource === 'userPreferences') {
      return getUserPreferences(resource, params);
    }
    if (resource === 'therapistSchedule') {
      return getScheduleList(resource, params);
    }
    if (resource === 'promo') {
      return getPromoList(resource, params);
    }
    if (resource === 'gift') {
      return getGiftList(resource, params);
    }
    if (resource === 'gift-ship-queue') {
      return getShipQueueList(resource, params);
    }
    if (resource === 'walletTransactions') {
      return getWalletTransactions(resource, params);
    }
    if (resource === 'locations') {
      return getLocations(resource, params);
    }
    if (resource === 'userlocations') {
      return getUserWorkLocations(resource, params);
    }
    if (resource === 'allTransactions') {
      return getTransactionsHistory(resource, params);
    }
    if (resource === 'services') {
      return getServices(resource, params);
    }
    if (resource === 'otherStaff') {
      return getOtherStaffList(resource, params);
    }
    if (resource === 'inactiveUsers') {
      return getInactiveUsers(resource, params);
    }
    if (resource === 'therapists-rating-report') {
      return getTherapistsRatingReport(resource, params);
    }
    if (resource === 'scoreboard-report') {
      return getScoreboardReport(resource, params);
    }
    if (resource === 'distribution-report') {
      return getDistributionReport(resource, params);
    }
    if (resource === 'tips-report') {
      return getTipsReport(resource, params);
    }
    if (resource === 'hall-squeezers-report') {
      return getHallSqueezersReport(resource, params);
    }
    if (resource === 'cancelMembershipReport') {
      return getCancelMembershipReportList(resource, params);
    }
    if (resource === 'giftsReport') {
      return getGiftsReport(resource, params);
    }
    if (resource === 'promo-revenue-report') {
      return getPromoRevenueReport(resource, params);
    }
    if (resource === 'squeezebucks-revenue-report') {
      return getSqueezeBucksRevenueReport(resource, params);
    }
  },
  getOne: (resource, params) => {
    if (resource === 'waiver') {
      return getWaiver(resource, params); 
     }
    if (resource === 'guest') {
      return getUser(resource, params);
    }
    if (resource === 'employee') {
      return getTherapist(resource, params);
    }
    if (resource === 'appointment') {
      return getAppointment(resource, params);
    }
    if (resource === 'gift') {
      return getGift(resource, params);
    }
    if (resource === 'userPreferences') {
      return getUserPreferences(resource, params);
    }
    if (resource === 'membership') {
      return getUserMembership(resource, params);
    }
    if (resource === 'promo') {
      return getPromo(resource, params);
    }
    if (resource === 'profile') {
      return getUserProfile(resource, params);
    }
  },
  update: (resource, params) => {
    if (resource === 'membershipType') {
      return updateMembershipType(resource, params);
    }
    if (resource === 'guest') {
      return updateUser(resource, params);
    }
    if (resource === 'appointment') {
      return updateAppointment(resource, params);
    }
    if (resource === 'review') {
      return updateReview(resource, params);
    }
    if (resource === 'wallet') {
      return updateUserWallet(resource, params);
    }
    if (resource === 'cancelMembership') {
      return updateCancelMembership(resource, params);
    }
    if (resource === 'editMembershipResumeDate') {
      return updateMembershipResumeDate(resource, params);
    }
    if (resource === 'redeemGift') {
      return redeemGift(resource, params);
    }
    if (resource === 'refund') {
      return updateGift(resource, params);
    }
    if (resource === 'user') {
      return updateUserInfo(resource, params);
    }
    if (resource === 'employee') {
      return updateTeamMembers(resource, params);
    }
    if (resource === 'appointmentRefund') {
      return appointmentRefund(resource, params);
    }
    if (resource === 'transactionsRefund') {
      return transactionsRefund(resource, params);
    }
    if (resource === 'updateTeamUser') {
      return updateTeamUser(resource, params);
    }
    if (resource === 'profile') {
      return updateUserProfile(resource, params);
    }
    if (resource === 'password') {
      return resetPassword(resource, params);
    }
    if (resource === 'forgotPassword') {
      return forgotPassword(resource, params);
    }
    if (resource === 'promo') {
      return updatePromo(resource, params);
    }
  },
  create: (resource, params) => {
    if (resource === 'waiver') {
      return createWaiver(resource, params);
    }
    if (resource === 'promo') {
      return createPromo(resource, params);
    }
    if (resource === 'gift') {
      return purchaseGift(resource, params);
    }
    if (resource === 'employee') {
      return createMember(resource, params);
    }
  },

  getShipList: (resource, params) => {
    return getShipQueueList(resource, params);
  },
};
