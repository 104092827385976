/* eslint-disable import/no-anonymous-default-export */
import { showToast } from '../utils';
import { getDateTime } from '../utils';
const { client } = require('../services/api-client');
const { constructUrl } = require('../utils/api');

const getTherapistsList = async (_resource, params) => {
  try {
    let { filter, pagination } = params;
    if (!filter.location_id) {
      filter.location_id = JSON.parse(localStorage.getItem('selectedLocation'));
    }
    const url = constructUrl('therapists', {
      // queryParams: queryParams,
      filters: filter,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getList - therapists', 'error');
    return Promise.reject(err);
  }
};

const getOtherStaffList = async (_resource, params) => {
  try {
    let { filter, pagination } = params;
    const url = constructUrl('users', {
      pagination,
      filters: filter,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getList - other staffs', 'error');
    return Promise.reject(err);
  }
};

const getTherapist = async (_resource, params) => {
  try {
    const { id } = params;
    // const filters = { id, ...params.filter };

    const url = constructUrl(`therapists?id=${id}`, { pagination: false });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    showToast('Error in getTherapist - employee', 'error');
    return Promise.reject(err);
  }
};

const updateTeamMembers = async (_resource, params) => {
  try {
    const {
      id,
      bio,
      pregnancy,
      license_number,
      license_expiration,
      first_name,
      last_name,
      phone,
      // admin_labor_rate,
      // massage_labor_rate,
    } = params.data;
    const url = constructUrl(`therapist/${id}`, { pagination: false });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: {
        bio,
        pregnancy: pregnancy ? true : false,
        license_number,
        license_expiration,
        first_name,
        last_name,
        phone,
      },
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in update Therapist - employee', 'error');
    return Promise.reject(err);
  }
};

const updateTeamUser = async (_resource, params) => {
  try {
    const { id, is_active } = params;
    const url = constructUrl('user', { pagination: false });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: {
        id,
        is_active,
      },
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in update employee', 'error');
    return Promise.reject(err);
  }
};

const getInactiveUsers = async (_resource, params) => {
  try {
    let { filter, pagination } = params;
    const url = constructUrl('users', {
      pagination,
      filters: filter,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getList - inactive users', 'error');
    return Promise.reject(err);
  }
};

const createMember = async (_resource, params) => {
  let data = params.data;
  data.is_active = true;
  // data.location_id = data.location_id.join(', ');
  data.dob = getDateTime(data.dob, false);
  if (data.license_expiration) {
    const formattedDate = getDateTime(data.license_expiration, false);
    data.license_expiration = formattedDate;
  }
  if (data.role !== 'therapist') {
    delete data.pregnancy;
  }

  const url = constructUrl('admin', {});
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data,
    });
    return { data: resp };
  } catch (err) {
    showToast(err ? err.message : 'Error in create Member - Member', 'error');
    return Promise.reject(err);
  }
};

export {
  getTherapistsList,
  getTherapist,
  updateTeamMembers,
  updateTeamUser,
  getOtherStaffList,
  getInactiveUsers,
  createMember,
};
