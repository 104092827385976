import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { MyUserMenu } from './user-menu';
import Logo from '../assets/squeeze_logo.png';

const useStyles = makeStyles({
  bar: {
    paddingTop: 10,
    paddingBottom: 10,
    color: 'white',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: 33,
    marginRight: 30,
  },
});

export const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar className={classes.bar} {...props} userMenu={<MyUserMenu />}>
      <img className={classes.logo} src={Logo} alt="logo" />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};
