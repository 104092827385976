import { useState } from 'react';
import Select from 'react-select';
import { SimpleShowLayout } from 'react-admin';
import { Link } from 'react-router-dom';
import momentTimezone from 'moment-timezone';
import '../../assets/main.css';
import { CloseAppointment } from './close-appointment';
import {
  getAppointmentDateTime,
  getFormattedDateTime,
  getFormattedDate,
  getFormattedDate_T,
  isTherapist,
} from '../../utils';
import { MASSAGE_REASON_OPTIONS } from '../../utils/constants';
import { useAppointmentTherapistNotes } from '../../hooks/useAppointmentTherapistNotes';

export const Details = ({
  refetchAppointment,
  refetchUserPreferences,
  updatePreferences,
  preferences: { preferencesArray, fetchingPreferences, updatingPreferences },
  appointment,
  appointmentId,
  userId,
}) => {
  const [massageReason, setMassageReason] = useState(MASSAGE_REASON_OPTIONS[0]);

  const onMassageReasonChange = item => {
    setMassageReason(item);
    updatePreferences({
      id: userId,
      preferences: { massage_reason: item?.value },
    }).then(() => refetchUserPreferences());
  };

  const {
    data: appointmentTherapistNotes,
    // loading: loadAppointmentTherapistNotes,
    // error: appointmentTherapistNotesError,
    refetch: refetchAppointmentTherapistNotes,
  } = useAppointmentTherapistNotes({
    // therapistId: appointment?.therapist.id,
    userId,
  });
  const timezone = appointment?.timezone;
  const startDate = appointment?.start_at.split(' ')[0];
  const startTime = appointment?.start_at.split(' ')[1];
  const endTime = appointment?.end_at.split(' ')[1];
  const massagePreference = preferencesArray?.find(
    preference => preference.key === 'massage_reason',
  );
  const extraInfo = preferencesArray?.find(
    preference => preference.key === 'extra_info',
  );
  const role = localStorage.getItem('role');
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  return (
    <>
      <div className="is-flex p-4">
        <div className="flex flex-row pl-4 pr-4">
          {isTherapist(role) ? (
            <h2 className="text-xl font-bold">
              {appointment?.user.first_name}{' '}
              {appointment?.user.last_name?.charAt(0)}
            </h2>
          ) : (
            <h2 className="text-xl font-bold blueLink">
              <Link className="underline" to={`/guest/${userId}`}>
                {appointment?.user.first_name} {appointment?.user.last_name}
              </Link>
            </h2>
          )}
          <div className="pl-4 font-medium">
            {`${getAppointmentDateTime(
              appointment?.start_at,
              appointment?.end_at,
              timezone,
            )}`}
          </div>
        </div>
        <SimpleShowLayout className="right-panel view">
          <div className="pt-2 mb-8 guest-details-wrap">
            <div className="guest-details-fields">
              <div className="label">Service:</div>
              <div className="capitalize">{appointment?.service.title}</div>
            </div>
            <div>
              <div className="label">Therapist:</div>
              <div>
                {appointment?.therapist.first_name}{' '}
                {appointment?.therapist.last_name}{' '}
              </div>
            </div>
            <div>
              <div className="label">Last Visit:</div>
              <div>
                {appointment?.user?.last_appointment_date
                  ? getFormattedDate_T(
                      appointment?.user?.last_appointment_date,
                      appointment?.user?.last_appointment_timezone,
                    )
                  : ''}
              </div>
            </div>

            {/* {isTherapist(role) && (
              <> */}
            <div>
              <div className="label">Last Visit w/ Me:</div>
              <div>
                {appointment?.user?.last_appointment_therapist &&
                loggedUser.id === appointment?.therapist?.user_id
                  ? getFormattedDate_T(
                      appointment?.user?.last_appointment_therapist,
                      appointment?.user?.last_appointment_timezone,
                    )
                  : ''}
              </div>
            </div>
            {/* </>
            )} */}
          </div>
        </SimpleShowLayout>
        <div className="mb-8 px-4">
          <div className="font-semibold pb-2">
            Why are you getting a massage?
          </div>
          <div>
            {!massagePreference?.value && (
              <div class="flex flex-row p-2">
                <Select
                  className="w-60"
                  value={null}
                  isSearchable={false}
                  options={MASSAGE_REASON_OPTIONS}
                  onChange={item => onMassageReasonChange(item)}
                />
              </div>
            )}
            {massagePreference?.value && (
              <div>{massagePreference?.value || ''}</div>
            )}
          </div>
        </div>

        <div className="mb-8 px-4">
          <div className="font-semibold pb-2">
            Anything else you want the therapist to know?
          </div>
          <div>{extraInfo?.value}</div>
        </div>
        <CloseAppointment
          className="px-4"
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          therapistNotes={appointmentTherapistNotes}
          refetchTherapistNotes={refetchAppointmentTherapistNotes}
          refetchAppointment={refetchAppointment}
          appointmentId={appointmentId}
          appointment={appointment}
          userId={userId}
          preferences={preferencesArray}
        />
      </div>
    </>
  );
};
