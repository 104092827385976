import { keyBy } from 'lodash';
import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
  useQuery
} from 'react-admin';
import { getFormattedDate_T } from '../utils';

const CancelMembershipReport = ({ filters, otherFilters }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'cancelMembershipReport',
    payload: {
      filters: { ...filters, ...otherFilters },
      pagination: { page, perPage },
    },
  });
  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[100]} {...props} />
  );
  return (
    <>
      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField label="Guest Email" render={record => record.email} />
          <FunctionField
            label="Cancellation Date "
            render={record => getFormattedDate_T(record.cancelled_at)}
          />
          <FunctionField
            label="Cancel Reason "
            render={record => record.cancel_reason}
          />
          <FunctionField
            label="Canceled from PP"
            render={record =>
              record.cancelled_from_pp === null
                ? ''
                : record.cancelled_from_pp === true
                ? 'Yes'
                : 'No'
            }
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
export default CancelMembershipReport;
