import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Pagination,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';

const RatingsReport = ({ filters, otherFilters }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'therapists-rating-report',
    payload: {
      filters: { ...filters, ...otherFilters },
      pagination: { page, perPage },
    },
  });
  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[100]} {...props} />
  );
  return (
    <>
      <ListContextProvider
        value={{
          ...data,
          resource: 'report',
          basePath: '/report/therapist-ratings-reports',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Therapist Name"
            render={record => record.full_name}
          />
          <FunctionField
            label="Total "
            render={record => record.average_rating}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
export default RatingsReport;
