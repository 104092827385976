import React, { useState } from 'react';
import history from '../history';

export const TeamMembersTabs = ({ selectedTab }) => {
  const [tab, setTab] = useState(selectedTab || 'therapists');

  const handleTabChange = value => {
    setTab(value);
    if (value === 'therapists') history.push(`/employee/therapists`);
    if (value === 'otherStaff') history.push(`/employee/other-staff`);
    if (value === 'inactive') history.push(`/employee/inactive-users`);
  };

  return (
    <div className="flex my-4">
      <div className="self-center pr-4">View By:</div>
      <div className="flex-auto">
        <select
          className="border-customized-input"
          name="members"
          id="members"
          onChange={e => handleTabChange(e.target.value)}
          defaultValue={tab}
        >
          <option value="therapists">Therapists</option>
          <option value="otherStaff">Other Staff</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    </div>
  );
};
