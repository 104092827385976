import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/browser';
import StripeTerminalInit from './utils/third-party-plugins/stripe-terminal-init';
import EdgeFullStoryInit from './utils/third-party-plugins/edge-full-story-init';

StripeTerminalInit();
EdgeFullStoryInit();

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
Sentry.init({
  dsn: sentryDsn,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
