import CloseIcon from '@material-ui/icons/Close';
import { keyBy } from 'lodash';
import momentTimezone from 'moment-timezone';
import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
  TextField,
  useQuery
} from 'react-admin';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import history from '../history';
import { cancelAppointment } from '../services/preferences';
import {
  getAppointmentDateTime,
  getCcAmount,
  getSbAmount,
  isBreakroom,
  isTherapist
} from '../utils';
import {
  GUESTS,
  TEAM_MEMBERS,
  WAIVE_CANCELLATION_WINDOW_IN_HOURS
} from '../utils/constants';

export const UserUpcomingAppointment = ({
  permissions,
  card = GUESTS,
  ...props
}) => {
  const [disableCancelBtn, setDisableCancelBtn] = React.useState(false);
  const { therapistId } = useParams();
  const filters =
    card === GUESTS ? { user_id: props.id } : { therapist_id: therapistId };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [appointment, setAppointment] = React.useState(null);
  const [showCancelPopup, setShowCancelPopup] = React.useState(false);
  const [waiveCancellationFee, setWaiveCancellationFee] = useState(false);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'appointment',
    payload: {
      pagination: { page, perPage },
      filters: { ...filters, status: 'upcoming', order_by: 'desc' },
    },
  });
  if (loading) return <Loading />;
  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );
  const onRowClick = (id, record) => {
    const baseRoute = card === GUESTS ? '/guest' : '/employee';
    return history.push(
      `${baseRoute}/appointment/${id}/view-guest-details/${record.user.id}`,
    );
  };

  const onCancelAppointment = appointmentId => {
    setDisableCancelBtn(true);
    cancelAppointment(
      appointmentId,
      { waive_cancellation_fee: waiveCancellationFee },
      {},
    )
      .then((data) => {
        if (data) {
          setDisableCancelBtn(false);
        }

        setShowCancelPopup(false);
        window.location.reload();
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  const handleAppointmentCancel = record => {
    setAppointment(record);
    setShowCancelPopup(true);
  };

  const cancelModal = () => {
    const isWaiveCancellationFee = appointment => {
      const diff = momentTimezone
        .utc(appointment?.start_at)
        .tz(appointment?.timezone)
        .diff(momentTimezone.tz(appointment?.timezone), 'hours');
      const isWithinCancellation =
        Math.sign(diff) === 1 || Math.sign(diff) === 0
          ? diff < WAIVE_CANCELLATION_WINDOW_IN_HOURS
          : false;
      return isWithinCancellation;
    };
    return (
      <Modal isOpen={showCancelPopup}>
        <div className="modal-header">
          <div className="light-text">Cancel Appointment</div>
          <CloseIcon
            onClick={() => setShowCancelPopup(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          Are you sure you want to cancel this appointment?
        </div>
        {appointment && isWaiveCancellationFee(appointment) && (
          <div>
            <input
              type="checkbox"
              id="waiveCancellationFee"
              name="cancellationFee"
              value=""
              checked={waiveCancellationFee}
              onClick={e => setWaiveCancellationFee(e.target.checked)}
            />
            <label> Waive cancelation fee?</label>
          </div>
        )}
        <div className="btn-padding">
          <button
            className="button-blue"
            disabled={disableCancelBtn ? true : false}
            onClick={() => onCancelAppointment(appointment?.id)}
          >
            Cancel Appointment
          </button>
        </div>
        {disableCancelBtn && (
          <div style={{ color: 'darkgray', fontSize: '0.98rem' }}>
            Please wait, cancelling your appointment...
          </div>
        )}
      </Modal>
    );
  };

  return (
    <>
      {cancelModal()}
      <ListContextProvider
        value={{
          resource: 'appointment',
          basePath: `/guest/${props.id}`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        className="main-table"
      >
        <Datagrid>
          <FunctionField
            label="Date/Time"
            render={record =>
              getAppointmentDateTime(
                record?.start_at,
                record?.end_at,
                record?.timezone,
              )
            }
          />
          {card === GUESTS && (
            <TextField source="therapist.first_name" label="Therapist" />
          )}
          {card === TEAM_MEMBERS && (
            <TextField source="user.first_name" label="User" />
          )}
          <TextField source="location.city" label="Location" />
          <TextField source="service.label" label="Service Type" />
          <FunctionField
            label="SBs Used"
            render={record =>
              record.transactions.length ? getSbAmount(record.transactions) : ''
            }
          />
          <FunctionField
            label="CC Total"
            render={record =>
              record.transactions.length ? getCcAmount(record.transactions) : ''
            }
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    onRowClick(record.id, record);
                  }}
                >
                  View
                </button>
              );
            }}
          />
          {!isBreakroom(permissions) &&
            !isTherapist(permissions) &&
            card === GUESTS && (
              <FunctionField
                render={record => {
                  return (
                    <button
                      className="button-blue"
                      onClick={() => {
                        handleAppointmentCancel(record);
                      }}
                    >
                      Cancel
                    </button>
                  );
                }}
              />
            )}
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
