import * as React from 'react';
import Select from 'react-select';
import history from '../../history';
import { isDayBirthday } from '../../utils';
import {
  AROMA_THERAPY_OPTIONS,
  BED_HEAT_THERAPY_OPTIONS,
  MUSIC_OPTIONS,
  // TEMPERATURE_OPTIONS,
  OIL_OR_LOTION_OPTIONS,
  PERCUSSION_THERAPY_OPTIONS
} from '../../utils/constants';

export const MassagePreferences = ({
  refetchUserPreferences,
  updatePreferences,
  aromaTherapy,
  lighting,
  music,
  heatTherapy,
  bedHeated,
  percussionTherapy,
  oilOrLotion,
  userId,
  appointment,
  user,
}) => {
  const [userAromaTherapy, setUserAromaTherapy] = React.useState(
    AROMA_THERAPY_OPTIONS[0],
  );
  const [userMusic, setUserMusic] = React.useState(MUSIC_OPTIONS[0]);
  const [userHeatTherapy, setUserHeatTherapy] = React.useState(
    BED_HEAT_THERAPY_OPTIONS[0],
  );
  const [userBedHeated, setUserBedHeated] = React.useState(
    BED_HEAT_THERAPY_OPTIONS[0],
  );
  const [userPercussionTherapy, setuserPercussionTherapy] = React.useState(
    PERCUSSION_THERAPY_OPTIONS[3],
  );
  const [userOilOrLotion, setUserOilOrLotion] = React.useState(
    OIL_OR_LOTION_OPTIONS[0],
  );

  React.useEffect(() => {
    setUserAromaTherapy(
      AROMA_THERAPY_OPTIONS.find(
        option =>
          option.value.toLowerCase() === aromaTherapy?.value?.toLowerCase(),
      ) || null,
    );

    setUserMusic(
      MUSIC_OPTIONS.find(
        option => option.value.toLowerCase() === music?.value?.toLowerCase(),
      ) || null,
    );
    setUserHeatTherapy(
      BED_HEAT_THERAPY_OPTIONS.find(
        option => option.value === Number(heatTherapy?.value),
      ) || null,
    );
    setUserBedHeated(
      BED_HEAT_THERAPY_OPTIONS.find(
        option => option.value === Number(bedHeated?.value),
      ) || null,
    );
    setuserPercussionTherapy(
      PERCUSSION_THERAPY_OPTIONS.find(option => {
        if (!option.value || !percussionTherapy?.value)
          return option.value === percussionTherapy?.value;
        return (
          option.value.toLowerCase() === percussionTherapy?.value.toLowerCase()
        );
      }) || null,
    );
    setUserOilOrLotion(
      OIL_OR_LOTION_OPTIONS.find(
        option =>
          option.label.toLowerCase() === oilOrLotion?.value?.toLowerCase(),
      ) || null,
    );
  }, [
    aromaTherapy,
    lighting,
    // temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
  ]);

  const onMassagePreferencesChange = (item, pref) => {
    if (pref === 'aromatherapy') {
      setUserAromaTherapy(item);
      updatePreferences({
        id: userId,
        preferences: { aromatherapy: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'music') {
      setUserMusic(item);
      updatePreferences({
        id: userId,
        preferences: { music: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'oil_lotion') {
      setUserOilOrLotion(item);
      updatePreferences({
        id: userId,
        preferences: { oil_lotion: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'heated_bed') {
      setUserBedHeated(item);
      updatePreferences({
        id: userId,
        preferences: { heated_bed: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'percussion_therapy') {
      setuserPercussionTherapy(item);
      updatePreferences({
        id: userId,
        preferences: { percussion_therapy: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'heat_therapy') {
      setUserHeatTherapy(item);
      updatePreferences({
        id: userId,
        preferences: { heat_therapy: item?.value },
      }).then(() => refetchUserPreferences());
    }
    if (pref === 'percussion_therapy') {
      setuserPercussionTherapy(item);
      updatePreferences({
        id: userId,
        preferences: { percussion_therapy: item?.value },
      }).then(() => refetchUserPreferences());
    }
  };

  const appointment_timezone = appointment && appointment.timezone;
  const iconWrap = icon => (
    <span className="icon-wrapper">
      <div class="fc-title-icon icon-fontsize w-10">{icon}</div>
    </span>
  );

  const renderIcons = () => {
    return (
      <div>
        {user && user.has_membership ? iconWrap('star_sharp') : ''}
        {user && user.minor ? iconWrap('escalator_warning') : ''}
        {user && user.vi_squeeze ? iconWrap('emoji_events_sharp') : ''}
        {user && user.pregnant ? iconWrap('local_hospital') : ''}
        {user && user.dob && isDayBirthday(user.dob, appointment_timezone)
          ? iconWrap('cake')
          : ''}
        {user && user.first_time_user ? iconWrap('fiber_new_sharp') : ''}
      </div>
    );
  };

  return (
    <div>
      <div className="big-icon-wrapper mt-4">{renderIcons()}</div>
      <div className="mt-4 mb-4 p-3 flex-row-reverse border-4	border-solid border-gray-100 rounded-xl is-flex">
        <h1 className="pl-2 pb-4 text-lg font-bold">
          Massage & Suite Preferences
        </h1>
        <div class="flex flex-col">
          <div class="flex flex-row p-2">
            <span class="">Music</span>{' '}
            <Select
              className="w-40"
              value={userMusic}
              isSearchable={false}
              options={MUSIC_OPTIONS}
              onChange={item => onMassagePreferencesChange(item, 'music')}
            />
          </div>
          <div class="flex flex-row p-2">
            <span class="">Aromatherapy</span>{' '}
            <Select
              className="w-40"
              value={userAromaTherapy}
              isSearchable={false}
              options={AROMA_THERAPY_OPTIONS}
              onChange={item =>
                onMassagePreferencesChange(item, 'aromatherapy')
              }
            />
          </div>

          <div class="flex flex-row p-2">
            <span class="">Oil or Lotion</span>
            <Select
              className="w-40"
              value={userOilOrLotion}
              isSearchable={false}
              options={OIL_OR_LOTION_OPTIONS}
              onChange={item => onMassagePreferencesChange(item, 'oil_lotion')}
            />
          </div>
          <div class="flex flex-row p-2">
            <span class="">Table Heated</span>{' '}
            <div className="flex flex-row">
              <Select
                className="w-40"
                value={userBedHeated}
                isSearchable={false}
                options={BED_HEAT_THERAPY_OPTIONS}
                onChange={item =>
                  onMassagePreferencesChange(item, 'heated_bed')
                }
              />
            </div>
          </div>
          <div class="flex flex-row p-2">
            <span class="">Heat Therapy</span>
            <div className="flex flex-row">
              <Select
                className="w-40"
                value={userHeatTherapy}
                isSearchable={false}
                options={BED_HEAT_THERAPY_OPTIONS}
                onChange={item =>
                  onMassagePreferencesChange(item, 'heat_therapy')
                }
              />
              {/* <span className="text-xs self-end">0=No, 1=Yes</span> */}
            </div>
          </div>
          <div class="flex flex-row p-2">
            <span class="">Percussion Therapy</span>{' '}
            <div className="flex flex-row">
              <Select
                className="w-40"
                value={userPercussionTherapy}
                isSearchable={false}
                options={PERCUSSION_THERAPY_OPTIONS}
                onChange={item =>
                  onMassagePreferencesChange(item, 'percussion_therapy')
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx---10 mt-4 text-center">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-2 mb-2 "
          onClick={() => {
            const baseRoute = '/guest';
            return history.push(
              `${baseRoute}/view-guest-details/${userId}/back-preferences`,
            );
          }}
        >
          View Back Preferences
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={() => {
            const baseRoute = '/guest';
            return history.push(
              `${baseRoute}/view-guest-details/${userId}/front-preferences`,
            );
          }}
        >
          View Front Preferences
        </button>
      </div>
    </div>
  );
};
