import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Loading, Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { updatePreferences } from '../../services/preferences';
import FocusPressureFront from './focus-pressure-front';

export const GuestFrontPreference = () => {
  let { userId } = useParams();

  const {
    userPreferences,
    loadingUserPreferences,
    // userPreferencesError,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const focusPressureFrontProps = {
    updatePreferences,
    refetchUserPreferences,
    // setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    // history,
    step: '2',
    userId,
    // resetUpdatedPreferences,
    isFromSummary: true,
  };

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Front Preferences" />
      {loadingUserPreferences && <Loading />}
      {!loadingUserPreferences && <CardContent>
        <div class="flex-X flex-row-X">
          <FocusPressureFront className="m-4" {...focusPressureFrontProps} />
        </div>
      </CardContent>}
    </Card>
  );
};
