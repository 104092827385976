import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import momentTimezone from 'moment-timezone';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import {
  finishAppointment,
  // checkInAppointment,
  addTherapistNote,
  // cancelAppointment,
  // noshowAppointment,
} from '../../services/preferences';
import '../../assets/main.css';
import './focus-pressure-back.css';
import { APPOINTMENT_STATUS } from '../../utils/constants';
import { getFormattedDate_T, getUtcToLocalDateTime, showToast } from '../../utils';
// import { isToday } from '../../utils';

export const CloseAppointment = ({
  startDate,
  startTime,
  endTime,
  therapistNotes,
  refetchAppointment,
  refetchTherapistNotes,
  className,
  appointment,
  appointmentId,
  userId,
  preferences,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [therapistNote, setTherapistNote] = useState('');
  const [loader, setLoader] = useState({
    finishAppointment: false,
    checkInAppointment: false,
    noshowAppointment: false,
  });
  const [preferencesFilled, setPreferencesFilled] = useState(false);
  let history = useHistory();

  useEffect(() => {
    isPreferencesFilled();
  });

  const onFinishAppointment = () => {
    setLoader({ ...loader, finishAppointment: true });
    finishAppointment(appointmentId, {
      // comments: therapistNote,
    })
      .then(({ error, message, id, therapist_id, user_id }) => {
        setLoader({ ...loader, finishAppointment: false });
        refetchAppointment();
        // history.push(`/guest/${userId}`);
      })
      .catch(error => {
        setLoader({ ...loader, finishAppointment: false });
        if (error) {
          showToast(error?.message, 'error');
        }
      });
  };
  // const onNoshowAppointment = () => {
  //   setLoader({ ...loader, noshowAppointment: true });
  //   noshowAppointment(appointmentId, {})
  //     .then(({ id }) => {
  //       setLoader({ ...loader, noshowAppointment: false });
  //       history.push(`/guest/${userId}`);
  //     })
  //     .catch(error => {
  //       setLoader({ ...loader, noshowAppointment: false });
  //       if (error) {
  //         console.error(error, 'error');
  //       }
  //     });
  // };

  // const onCheckIn = () => {
  //   setLoader({ ...loader, checkInAppointment: true });

  //   checkInAppointment(appointmentId)
  //     .then(({ error, message, id }) => {
  //       setLoader({ ...loader, checkInAppointment: false });
  //       if (error) {
  //         console.error(message, 'error');
  //       }
  //       refetchAppointment();
  //     })
  //     .catch(error => {
  //       setLoader({ ...loader, checkInAppointment: false });
  //       if (error) {
  //         console.error(error, 'error');
  //       }
  //     });
  // };

  const onAddTherapistNote = () => {
    const checkInParams = {
      // therapist_id,
      // service_id,
      notes: therapistNote,
      // slot_time: start_at,
    };
    setLoader({ ...loader, addTherapistNote: true });
    setTherapistNote('');
    addTherapistNote(appointmentId, checkInParams)
      .then(({ error, message, id }) => {
        setLoader({ ...loader, addTherapistNote: false });
        if (error) {
          showToast(error?.message, 'error');
        }
        refetchTherapistNotes();
      })
      .catch(error => {
        setLoader({ ...loader, addTherapistNote: false });
        if (error) {
          showToast(error?.message, 'error');
        }
      });
  };

  const updateTherapistNote = value => {
    setTherapistNote(value);
  };

  // const isBeforeTheAppointment = (startDate, endTime) => {
  //   return moment.utc().isBefore(moment.utc(`${startDate} ${endTime}`));
  // };

  const isAfterTheAppointment = (startDate, endTime) => {
    return momentTimezone
      .utc()
      .isAfter(momentTimezone.utc(`${startDate} ${endTime}`));
  };

  // const showCheckInButton =
  //   appointment?.status === APPOINTMENT_STATUS.CONFIRMED;

  //  && isToday(startDate);
  // && isBeforeTheAppointment(startDate, endTime);

  const isPreferencesFilled = () => {
    let filled = true;
    const keys = [
      'aromatherapy',
      'music',
      'oil_lotion',
      'heated_bed',
      'heat_therapy',
      'percussion_therapy',
    ];

    preferences &&
      preferences.length &&
      keys.forEach(key => {
        if (!preferences.find(p => p.key === key)) {
          filled = false;
          return;
        }
      });
    setPreferencesFilled(filled);
  };

  const showFinishAppointmentButton =
    appointment?.status === APPOINTMENT_STATUS.CHECK_IN &&
    isAfterTheAppointment(startDate, startTime);

  // const showCancelButton =
  //   appointment?.status === APPOINTMENT_STATUS.CONFIRMED &&
  //   isBeforeTheAppointment(startDate, endTime);

  // const showNoshowButton = appointment?.status === APPOINTMENT_STATUS.CONFIRMED;

  const finishAppointmentModal = () => {
    const header = 'Finish Appointment';
    const buttonText = 'Yes';
    const bodyContent = 'Are you sure you want to finish the appointment?';
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">{bodyContent}</div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue button-red"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              onClick={() => {
                setShowModal(false);
                onFinishAppointment();
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const confirmModal = () => {
    setShowModal(true);
  };

  const getTherapistNotes = therapistNotes => {
    // const data1 =
    //   therapistNotes && _.sortBy(therapistNotes, 'created_at').reverse();
    const notes =
      therapistNotes && therapistNotes.length > 3
        ? therapistNotes.slice(0, 3)
        : therapistNotes;
    return (
      notes &&
      notes.map(note => {
        return (
          <li>
            {getUtcToLocalDateTime(note?.created_at, false)} by{' '}
            {note?.user?.first_name || ''} - {note.notes}
          </li>
        );
      })
    );
  };

  return (
    <>
      {finishAppointmentModal()}
      <div className={className}>
        <label class="block">
          <span class="font-semibold text-gray-700">Add a Therapist note</span>
          <div className="mt-2 ">
            <div className="mt-2 border-2 border-solid p-2">
              <textarea
                class="form-textarea  mt-1 block w-full"
                rows="3"
                value={therapistNote}
                placeholder="Add a Therapist note..."
                onChange={e => updateTherapistNote(e.target.value)}
              ></textarea>
            </div>
            <button
              disabled={loader.addTherapistNote || therapistNote.length === 0}
              class="mt-4 items-center flex flex-row bg-blue-500 text-white font-bold py-2 px-4 rounded disabled:opacity-50 hover:bg-blue-700"
              onClick={onAddTherapistNote}
            >
              {loader.addTherapistNote && (
                <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
              )}
              <span>Add</span>
            </button>
          </div>
        </label>

        <ul class="flex flex-col mt-4 list-disc">
          {therapistNotes && getTherapistNotes(therapistNotes)}
        </ul>
        {therapistNotes && therapistNotes.length > 3 ? (
          <Link
            to={{
              pathname: `/guest/${userId}/appointment/${appointmentId}/therapist-notes`,
            }}
            className="underline"
          >
            See other Therapist notes
          </Link>
        ) : (
          ''
        )}

        {/* {showCheckInButton && (
          <button
            disabled={loader.checkInAppointment}
            class="items-center flex flex-row  mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50 hover:bg-blue-700"
            onClick={onCheckIn}
          >
            {loader.checkInAppointment && (
              <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
            )}
            <span>Checkin</span>
          </button>
        )} */}
        {showFinishAppointmentButton && (
          <button
            disabled={!preferencesFilled || loader.finishAppointment}
            class={
              preferencesFilled
                ? 'items-center flex flex-row  mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50 hover:bg-blue-700'
                : 'bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full'
            }
            onClick={() => confirmModal()}
          >
            {loader.finishAppointment && (
              <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
            )}
            <span>Finish Appointment</span>
          </button>
        )}
        {/* {showCancelButton && (
        <button
          disabled={loader.cancelAppointment}
          class="items-center flex flex-row  mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50 hover:bg-blue-700"
          onClick={onCancelAppointment}
        >
          {loader.cancelAppointment && (
            <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
          )}
          <span>Cancel Appointment</span>
        </button>
      )} */}
        {/* {showNoshowButton && (
          <button
            disabled={loader.noshowAppointment}
            class="items-center flex flex-row  mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50 hover:bg-blue-700"
            onClick={onNoshowAppointment}
          >
            {loader.noshowAppointment && (
              <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
            )}
            <span>No Show</span>
          </button>
        )} */}
      </div>
    </>
  );
};
