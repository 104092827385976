import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import {
  Datagrid,
  DateInput,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  useRefresh
} from 'react-admin';
import Modal from 'react-modal';
import '../appointment/appointment.css';
import { customDataProvider } from '../data-provider';
import '../Table.css';
import {
  getFormattedDateTime,
  getStarRatings,
  isAdmin,
  isGss,
  isTherapist
} from '../utils';
import { REVIEW_STATUS } from '../utils/constants';

export const ReviewList = ({ permissions, ...props }) => {
  const refresh = useRefresh();
  const [showModal, setShowModal] = useState(null);
  const [filter, setFilter] = useState(2);
  const [selectedFilter, setSelectedFilter] = useState(2);

  const avg_rating = localStorage.getItem('avg_rating');

  const approveContent =
    'Are you sure you want to approve this review? As soon as it is approved, it will be visible wherever the guest chose to share it.';
  const rejectContent =
    'Are you sure you want to reject this review? You should only reject reviews that have explicit or offensive content, or that which we believe to be untrue. If you are not sure, please consult your manager.';

  const updateReview = record => {
    const { id, share_to_public, share_to_therapist, action } = record;
    const approved = action === 'approve' ? 1 : 0;
    const payload = {
      is_approved: approved,
      share_to_public: !!share_to_public,
      share_to_therapist: !!share_to_therapist,
    };
    customDataProvider.update('review', { id, payload }).then(() => {
      setShowModal(false);
      refresh();
    });
  };

  const reviewModal = () => {
    const header =
      showModal?.action === 'approve' ? 'Approve review' : 'Reject Review';
    const buttonText = showModal?.action === 'approve' ? 'Approve' : 'Reject';
    const bodyContent =
      showModal?.action === 'approve' ? approveContent : rejectContent;
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">{bodyContent}</div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="button-blue button-red"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              onClick={() => updateReview(showModal)}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const handleReviewChange = (record, action) => {
    const reviewObj = { ...record, action };
    setShowModal(reviewObj);
  };
  const ReviewFilter = props => (
    <Filter {...props} className="mb-2">
      {!isTherapist(permissions) && (
        <SelectInput
          label="Status"
          source="is_approved"
          alwaysOn
          choices={REVIEW_STATUS}
          allowEmpty={false}
          onChange={e => {
            console.log('TARGET:', e.target.value);
            setFilter(e.target.value);
            setSelectedFilter(e.target.value);
          }}
          initialValue={selectedFilter ? selectedFilter : 2}
        />
      )}
      <TextInput
        source="user_name"
        placeholder="Guest Name"
        label="Guest Name"
      />
      <TextInput
        source="therapist_name"
        placeholder="Therapist Name"
        label="Therapist Name"
      />
      <DateInput source="start_at_lower" label="Appointment Date >" />
      <DateInput source="start_at_upper" label="Appointment Date <" />
      <TextInput source="comments" placeholder="Comments " label="Comments" />
    </Filter>
  );
  return (
    <>
      {reviewModal()}
      <div className="text-xl my-4 review_title">
        DO WHAT YOU LOVE & LET IT LOVE YOU BACK
      </div>
      {isTherapist(permissions) && avg_rating && (
        <div className="flex-only mb-4">
          <div className="header_info">Average Rating: </div>
          <div className="header_info ml-2 flex-only">
            {avg_rating}
            <div className="ml-2">{getStarRatings(avg_rating)}</div>
          </div>
        </div>
      )}
      <List
        filters={<ReviewFilter />}
        {...props}
        perPage={25}
        bulkActionButtons={false}
        className="main-table"
        exporter={false}
      >
        <Datagrid rowClick="edit">
          <FunctionField
            label="Service Date/Time"
            render={record =>
              getFormattedDateTime(
                record?.appointment?.start_at,
                record?.appointment?.timezone,
              )
            }
          />
          {!isTherapist(permissions) && (
            <FunctionField
              label="Therapist"
              render={record => (
                <span>
                  {record?.therapist?.first_name || ''}{' '}
                  {record?.therapist?.last_name || ''}
                </span>
              )}
            />
          )}
          <FunctionField
            label="Location Name"
            render={record => record?.appointment?.location?.name}
          />
          <FunctionField
            label="Guest"
            render={record => (
              <a
                href={`/#/guest/${record?.user_id}`}
                alt={
                  record?.user?.first_name
                    ? record?.user?.first_name
                    : 'User Name'
                }
                style={{ textDecoration: 'underline' }}
              >
                {record?.user?.first_name}{' '}
                {isTherapist(permissions)
                  ? record?.user?.last_name?.charAt(0)
                  : record?.user?.last_name}
              </a>
            )}
          />
          {/* {!isTherapist(permissions) && (
            <FunctionField
              label="Status"
              render={record =>
                record?.is_approved
                  ? record.is_approved === 1
                    ? 'Approved'
                    : 'Pending'
                  : 'Rejected'
              }
            />
          )} */}
          {/* {(isAdmin(permissions) || isGss(permissions)) && (
            <FunctionField
              label="Public"
              render={record => (record.share_to_public ? 'Yes' : 'No')}
            />
          )} */}
          {/* {(isAdmin(permissions) || isGss(permissions)) && (
            <FunctionField
              label="Share to Therapist"
              render={record => (record.share_to_therapist ? 'Yes' : 'No')}
            />
          )} */}
          <FunctionField
            label="Rating"
            render={record => (
              <span className="flex-only">
                {getStarRatings(record?.rating)}
              </span>
            )}
          />
          <TextField label="Review" source="comment" />
          {(isAdmin(permissions) || isGss(permissions)) &&
            Number(filter) !== 1 && (
              <FunctionField
                render={record => (
                  <button
                    className="button-blue"
                    onClick={() => handleReviewChange(record, 'approve')}
                  >
                    Approve
                  </button>
                )}
              />
            )}
          {(isAdmin(permissions) || isGss(permissions)) &&
            Number(filter) !== 0 && (
              <FunctionField
                render={record => (
                  <button
                    className="button-blue button-red"
                    onClick={() => handleReviewChange(record, 'reject')}
                  >
                    Reject
                  </button>
                )}
              />
            )}
        </Datagrid>
      </List>
    </>
  );
};
