import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
// import { title } from 'change-case';

// Modal.setAppElement('#app');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '27rem',
  },
};

const defaultText =
  'To make sure your Therapist has adequate time to focus on the areas you want, you can select up to 3 to spend more time on.';

const FocusLimitModal = props => {
  const modelText = props.text || defaultText;
  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      contentLabel="Focus Limit"
    >
      <div className="giftSingleModal pref">
        <div className="bubble">
          <p>{modelText}</p>
        </div>
        <div className="preg-modal__smiley">
          <i className="icon icon--smiley-blue-reflection" />
        </div>

        <button
          className="btn btn--secondary home__btn prefLimitMoreBtn"
          onClick={props.close}
        >
          Okay
        </button>
      </div>
    </Modal>
  );
};

FocusLimitModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default FocusLimitModal;
