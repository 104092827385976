import { client } from '../services/api-client';
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';
const authKey = 'AUTH_TOKEN';

const getToken = () => localStorage.getItem(authKey);
const setToken = token => localStorage.setItem(authKey, token);
const removeToken = () => localStorage.removeItem(authKey);

const login = async data => {
  const url = constructUrl('login', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data,
    });
    return resp;
  } catch (error) {
    showToast(error.message, 'error');
    return Promise.reject(error);
  }
};

const logout = async () => {
  return await client(`users/logout`, {
    method: 'DELETE',
    transform: true,
    authNeeded: true,
  });
};

export { authKey, getToken, setToken, removeToken, login, logout };
