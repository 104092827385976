import Card from '@material-ui/core/Card';
import moment from 'moment';
import { getFormattedDate_T } from '../utils';

export const GiftCardPurchaser = ({ giftDetails }) => {
  const deliveryDetailsArray = giftDetails?.giftcarddeliveries;
  const deliveryDetails = deliveryDetailsArray?.length
    ? deliveryDetailsArray[0]
    : {};

  const giftSender = giftDetails?.sender;

  const rowItem = (label, value) => {
    return (
      <>
        <div className="label">{label}</div>
        <div>{value}</div>
      </>
    );
  };

  return (
    <Card className="p-4 mb-4">
      <div className="m-2 text-lg font-bold">Purchaser</div>
      <div className="details-display align-center">
        {rowItem('Purchaser Name :', deliveryDetails?.sender_name || '')}
        {rowItem('Purchaser Email :', deliveryDetails?.sender_email || '')}
        {rowItem(
          'Purchase Date :',
          giftDetails?.paid_at ? getFormattedDate_T(giftDetails?.paid_at) : '',
        )}
        {rowItem('Delivery Type :', deliveryDetails?.delivery_mode)}
      </div>
    </Card>
  );
};
