import React, { useState } from 'react';
import _ from 'lodash';
import {
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  required,
  SimpleForm,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';

const validateReason = [required()];
const validateOther = [required()];
const validateAmount = [required()];

export const Refund = ({
  showRefund,
  setShowRefund,
  appointment,
  refetch,
  transaction,
  refundReasons,
}) => {
  const [reason, setReason] = useState('');
  const [fullRefund, setFullRefund] = useState(true);

  const handleSave = form => {
    const { amount, reason, otherReason } = form;
    let payload = {
      full_refund: fullRefund,
      reason: reason === 'Other' ? otherReason : reason,
    };
    if (!fullRefund) {
      payload.amount = amount;
    }
    if (appointment) {
      customDataProvider
        .update('appointmentRefund', {
          id: appointment?.id,
          payload,
        })
        .then(() => {
          setShowRefund(false);
          refetch();
        });
    } else {
      if (transaction) {
        customDataProvider
          .update('transactionsRefund', {
            id: transaction?.id,
            payload,
          })
          .then(() => {
            setShowRefund(false);
            refetch();
          });
      }
    }
  };

  const handleFullRefundChange = choice => {
    const fullRef = !_.isEmpty(choice);
    setFullRefund(fullRef);
  };

  const RefundToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Refund" onSave={handleSave} />
    </Toolbar>
  );

  return (
    <Modal isOpen={showRefund}>
      <div className="modal-header">
        <div className="light-text">Refund</div>
        <CloseIcon
          onClick={() => setShowRefund(false)}
          className="close-button"
        />
      </div>
      <SimpleForm
        toolbar={<RefundToolbar />}
        initialValues={{ refund: ['refund'] }}
      >
        <div>Are you sure you want to issue a refund to this guest?</div>
        <CheckboxGroupInput
          label=""
          source="refund"
          onChange={e => handleFullRefundChange(e)}
          choices={[{ id: 'refund', name: 'Process Full Refund' }]}
        />
        {!fullRefund && (
          <TextInput
            label="Refund Amount"
            source="amount"
            validate={validateAmount}
          />
        )}
        <SelectInput
          source="reason"
          validate={validateReason}
          onChange={e => setReason(e.target.value)}
          choices={refundReasons}
        />
        {reason === 'Other' && (
          <TextInput
            label="Other Reason"
            source="otherReason"
            validate={validateOther}
          />
        )}
      </SimpleForm>
    </Modal>
  );
};
