import { keyBy } from 'lodash';
import React from 'react';
import {
  Datagrid, FunctionField, ListContextProvider, Loading, Pagination, TextField,
  useQuery
} from 'react-admin';
import { useParams } from 'react-router-dom';
import history from '../history';
import {
  getAppointmentDateTime,
  getCcAmount,
  getSbAmount,
  getUtcToLocalDateTime
} from '../utils';
import { APPOINTMENT_REFUND, GUESTS, TEAM_MEMBERS } from '../utils/constants';
import { Refund } from './refund';

export const UserCancelledAppointment = ({ card, ...props }) => {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [showRefund, setShowRefund] = React.useState(false);
  const [appointment, setAppointment] = React.useState(null);
  const { therapistId } = useParams();
  const filters =
    card === GUESTS ? { user_id: props.id } : { therapist_id: therapistId };

  const { data, total, refetch, loading } = useQuery({
    type: 'getList',
    resource: 'appointment',
    payload: {
      pagination: { page, perPage },
      filters: { ...filters, status: 'cancelled', order_by: 'desc' },
    },
  });
  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  const onRowClick = record => {
    const baseRoute = card === GUESTS ? '/guest' : '/employee';
    return history.push(
      `${baseRoute}/appointment/${record.id}/view-guest-details/${record.user_id}`,
    );
  };

  const handleRefundClick = record => {
    setAppointment(record);
    setShowRefund(true);
  };

  return (
    <>
      {' '}
      <Refund
        showRefund={showRefund}
        setShowRefund={setShowRefund}
        appointment={appointment}
        refetch={refetch}
        refundReasons={APPOINTMENT_REFUND}
      />
      <ListContextProvider
        value={{
          resource: 'appointment',
          basePath: `/guest/${props.id}`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          perPage,
          setPerPage,
          setPage,
        }}
        // pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Date/Time"
            render={record =>
              getAppointmentDateTime(
                record?.start_at,
                record?.end_at,
                record?.timezone,
              )
            }
          />
          {card === GUESTS && (
            <TextField source="therapist.first_name" label="Therapist" />
          )}
          {card === TEAM_MEMBERS && (
            <TextField source="user.first_name" label="User" />
          )}
          <TextField source="location.city" label="Location" />
          <TextField source="service.label" label="Service Type" />
          <FunctionField
            label="Cancelled At"
            render={record => getUtcToLocalDateTime(record?.updated_at)}
          />
          <FunctionField
            label="SBs used"
            render={record =>
              record?.transactions?.length
                ? getSbAmount(record.transactions)
                : ''
            }
          />
          <FunctionField
            label="CC Total"
            render={record =>
              record.transactions?.length
                ? getCcAmount(record.transactions)
                : ''
            }
          />
          <FunctionField
            label="No Show?"
            render={record => (record.no_show ? 'Yes' : 'No')}
          />
          <FunctionField
            label="Payment Cancelled?"
            render={record => {
              if (record.is_charged === 2) {
                return 'True';
              } else if (record.is_charged === 1 || record.is_charged === 0) {
                return 'False';
              }

              return '';
            }}
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    onRowClick(record);
                  }}
                >
                  View
                </button>
              );
            }}
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    handleRefundClick(record);
                  }}
                >
                  Refund
                </button>
              );
            }}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
