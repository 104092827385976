import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  FunctionField,
} from 'react-admin';
import { useAppointmentTherapistNotes } from '../../hooks/useAppointmentTherapistNotes';
import { getFormattedDate_T } from '../../utils';
import { keyBy } from 'lodash';
import history from '../../history';

export const TherapistNotes = () => {
  let { userId, appointmentId } = useParams();
  const { data: appointmentTherapistNotes } = useAppointmentTherapistNotes({
    userId,
  });

  const data = appointmentTherapistNotes;
  return (
    <>
      <h1 className="text-2xl p-2">Therapist Notes</h1>
      {data && (
        <ListContextProvider
          value={{
            data: keyBy(data, 'id'),
            ids: data && data.map(({ id }) => id),
            currentSort: { field: 'id', order: 'ASC' },
          }}
          className="main-table"
        >
          <Datagrid>
            <FunctionField
              label="Date"
              render={record => getFormattedDate_T(record?.created_at)}
            />
            <FunctionField
              label="Therapist"
              render={record => record?.user?.first_name}
            />
            <TextField source="notes" label="Notes" />
          </Datagrid>
        </ListContextProvider>
      )}
      <div className="mx---10 mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4"
          onClick={() => {
            return history.push(
              `/guest/appointment/${appointmentId}/view-guest-details/${userId}`,
            );
          }}
        >
          Back to Appointment
        </button>
      </div>
    </>
  );
};
