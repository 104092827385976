import _ from 'lodash';
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getTherapistList = async (_resource, params) => {
  try {
    let { filter } = params;

    const url = constructUrl('therapists', {
      filters: filter,
      limit: 50,
    });

    const resp = await client(url, { authNeeded: true });
    const sortedItems = _.sortBy(resp.items, ['first_name']);
    const responseData = {
      data: sortedItems,
      total: resp.count,
    };
    localStorage.setItem('therapistsList', JSON.stringify(sortedItems));
    return responseData;
  } catch (err) {
    showToast('Error in getTherapistList - therapist', 'error');
    return Promise.reject(err);
  }
};

const getScheduleList = async (_resource, params) => {
  try {
    let { start_date, location_id, end_date, therapist_id } = params;
    const queryParams = therapist_id
      ? `start_date=${start_date}&location_id=${location_id}&end_date=${end_date}&therapist_id=${therapist_id}`
      : `start_date=${start_date}&location_id=${location_id}&end_date=${end_date}`;

    const url = constructUrl('therapists/schedule', {
      queryParams,
      pagination: false,
    });

    const resp = await client(url, { authNeeded: true });
    const _scheduleList = {
      data: resp,
    };
    localStorage.setItem('scheduleList', JSON.stringify(_scheduleList));
    return _scheduleList;
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

export { getTherapistList, getScheduleList };
