/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getUserPreferences } from '../user/user-queries';

export const useUserPreferences = ({ userId }) => {
  const [userPreferences, setUserPreferences] = useState(null);
  const [userPreferencesLoading, setUserPreferencesLoading] = useState(false);
  const [userPreferencesError, setUserPreferencesError] = useState(null);

  const fetchUserPreferences = async () => {
    try {
      setUserPreferencesLoading(true);
      const userPreferencesResponse = await getUserPreferences(
        'userPreference',
        { id: userId },
      );
      setUserPreferences(userPreferencesResponse.data);
      setUserPreferencesLoading(false);
    } catch (err) {
      setUserPreferencesError(err);
    }
  };
  useEffect(() => {
    fetchUserPreferences();
  }, [userId]);

  const aromaTherapy = userPreferences?.find(
    preference => preference.key === 'aromatherapy',
  );
  const lighting = userPreferences?.find(
    preference => preference.key === 'lighting',
  );
  const temperature = userPreferences?.find(
    preference => preference.key === 'temperature',
  );
  const music = userPreferences?.find(preference => preference.key === 'music');
  const heatTherapy = userPreferences?.find(
    preference => preference.key === 'heat_therapy',
  );
  const bedHeated = userPreferences?.find(
    preference => preference.key === 'heated_bed',
  );
  const oilOrLotion = userPreferences?.find(
    preference => preference.key === 'oil_lotion',
  );
  const percussionTherapy = userPreferences?.find(
    preference => preference.key === 'percussion_therapy',
  );

  return {
    aromaTherapy,
    lighting,
    temperature,
    music,
    heatTherapy,
    bedHeated,
    oilOrLotion,
    percussionTherapy,
    userPreferences,
    loadingUserPreferences: userPreferencesLoading,
    userPreferencesError,
    refetchUserPreferences: fetchUserPreferences,
  };
};
