import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  PasswordInput,
  required,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRefresh
} from 'react-admin';
import { useLocation } from 'react-router';
import { customDataProvider } from '../data-provider';
import history from '../history';
import { showToast } from '../utils';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion(currentVersion => currentVersion + 1),
    }),
    [profileVersion],
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = ({ staticContext, ...props }) => {
  const role = localStorage.getItem('role');
  const [isForcePasswordReset, setIsForcePasswordReset] = useState(false);
  const [hideAccountSetting, setHideAccountSetting] = useState(false);
  const location = useLocation();
  const bottom = useRef();

  React.useEffect(() => {
    if (location.pathname === '/profile') {
      setIsForcePasswordReset(true);
      setHideAccountSetting(true);
    }
  }, [location, isForcePasswordReset]);

  useEffect(() => {
    bottom.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [isForcePasswordReset]);

  const user = JSON.parse(localStorage.getItem('user'));
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const { refreshProfile } = useProfile();
  const refresh = useRefresh();

  const handleSave = useCallback(
    values => {
      setSaving(true);
      customDataProvider
        .update('profile', { data: values })
        .then(({ data }) => {
          if (data.id) {
            setSaving(false);
            localStorage.setItem('user', JSON.stringify(data));
            notify('Your profile has been updated', 'info', {
              _: 'Your profile has been updated',
            });
            refreshProfile();
          }
        });
    },
    [customDataProvider, notify, refresh],
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave],
  );

  const handlePasswordChange = form => {
    const { newPassword, confirmPassword } = form;
    if (newPassword !== confirmPassword) {
      showToast('New password & confirmation password do not match', 'error');
      return;
    }
    const payload = {
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    customDataProvider
      .update('password', {
        payload,
      })
      .then(() => {
        if (isForcePasswordReset) {
          showToast('Password has been reset successfully!', 'success');
          setHideAccountSetting(false);
          history.push(role === 'admin' ? '/guest' : 'appointment');
          localStorage.removeItem('force_password_reset');
          setTimeout(() => window.location.reload(), 0);
          return;
        }
        showToast(
          'Password has been reset successfully! Please login.',
          'success',
        );
        localStorage.clear();
        history.push('/login');
        window.location.reload();
      });
    setIsForcePasswordReset(false);
  };

  const PasswordToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Submit" onSave={handlePasswordChange} />
    </Toolbar>
  );

  const EditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton
        disabled={localStorage.getItem('force_password_reset') ? true : false}
        label="Submit"
        onSave={handleSave}
      />
    </Toolbar>
  );

  return (
    <>
      <h1 className="text-xl mt-6 font-bold">Account Settings</h1>
      <SaveContextProvider value={saveContext}>
        <SimpleForm toolbar={<EditToolbar />} record={user ? user : {}}>
          <TextInput source="first_name" validate={required()} />
          <TextInput source="last_name" validate={required()} />
          <TextInput source="email" validate={required()} />
        </SimpleForm>
      </SaveContextProvider>
      {!hideAccountSetting ? (
        <h1 className="text-xl mt-6 font-bold">Change Password</h1>
      ) : (
        <h1 className="text-xl mt-6 font-bold">Enter New Password</h1>
      )}
      <p className='text-gray-400 w-11/12 mx-0.3 mt-4'><span className='font-bold'>NOTE</span>: Make sure password contains atleast one uppercase,
        one lowercase, <br />
        one digit and one special character and length between 6 to 15</p>
      <div ref={bottom}></div>
      <SimpleForm
        toolbar={<PasswordToolbar />}
        initialValues={{ refund: ['refund'] }}
      >
        <PasswordInput
          label="New Password"
          placeholder="Password"
          source="newPassword"
          validate={required()}
        />
        <PasswordInput
          label=""
          placeholder="Confirm Password"
          source="confirmPassword"
          validate={required()}
        />
      </SimpleForm>

    </>
  );
};
