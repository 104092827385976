import React from 'react';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  SearchInput,
  TextField
} from 'react-admin';
import { getFormattedDate_T } from '../utils';

const PromoFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const onRowClick = (id, basePath, record) => {
  console.log(id, basePath, record, 'onRowClick');
  const role = localStorage.getItem('role');
  if (role === 'admin' || role === 'gss') {
    return 'edit';
  } else if (
    role === 'front_desk' ||
    role === 'shop_manager' ||
    role === 'operating-partner'
  ) {
    return `/promo/details/${id}`;
  } else {
    return 'show';
  }
};

export const PromoList = ({ ...props }) => {
  return (
    <List
      filters={<PromoFilter />}
      {...props}
      perPage={25}
      bulkActionButtons={false}
      className="main-table"
      exporter={false}
    >
      <Datagrid rowClick={onRowClick}>
        <TextField source="code" label="Promo Code" />
        <FunctionField
          label="Start Date"
          render={record => getFormattedDate_T(record.promo_start_date)}
        />
        <FunctionField
          label="End Date"
          render={record => getFormattedDate_T(record.promo_end_date)}
        />
        <TextField source="capacity" label="Capacity" />
        <TextField source="uses" label="Uses" />
      </Datagrid>
    </List>
  );
};
