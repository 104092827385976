import { showToast } from '../utils';
import { constructUrl } from '../utils/api';
const { client } = require('../services/api-client');

const getTipList = async (_resource, params) => {
  try {
    let { pagination } = params;
    let filters = params.filter || [];
    if (!filters || (!filters.user_id && !filters.therapist_id)) {
      const user = JSON.parse(localStorage.getItem('user'));
      user.role === 'therapist' && (filters.therapist_id = user.id);
    }
    const url = constructUrl('tips', {
      filters,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getTipList - tip', 'error');
    return Promise.reject(err);
  }
};

export { getTipList };
