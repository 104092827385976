import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  DateTimeInput,
  required,
  useQuery,
} from 'react-admin';
import '../user-styles.css';
import { getFormattedDate, getLocations } from '../utils';

const validateProducts = [required()];
const validateLocations = [required()];
const validateCode = [required()];
const validateCodeType = [required()];
const validateValue = [required()];
const validateDate = [required()];
const validateCapacity = [required()];

export const PromoCreate = props => {
  const [promoProducts, setPromoProducts] = useState([]);

  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));

  const todayDate = getFormattedDate(new Date());

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="code" label="Promo Code" validate={validateCode} />
        <RadioButtonGroupInput
          source="type"
          label="Code Type"
          choices={[
            { id: 1, name: '$ Off' },
            { id: 2, name: '% Off' },
          ]}
          validate={validateCodeType}
        />
        <TextInput
          source="discount_amount"
          label="Code Value ($/%)"
          validate={validateValue}
        />
        <DateTimeInput
          source="promo_start_date"
          defaultValue={todayDate}
          label="Start Date"
          validate={validateDate}
        />
        <DateTimeInput
          source="promo_end_date"
          defaultValue={todayDate}
          label="End Date"
          validate={validateDate}
        />
        <TextInput source="capacity" validate={validateCapacity} />
        <CheckboxGroupInput
          source="products"
          validate={validateProducts}
          onChange={e => setPromoProducts(e)}
          choices={[
            { id: 'appointment', name: 'appointment' },
            { id: 'gift', name: 'gift' },
            { id: 'membership', name: 'membership' },
          ]}
        />
        <CheckboxGroupInput
          source="restrictions"
          choices={[
            { id: 'membersOnly', name: 'Members only?' },
            { id: 'firstTimeGuest', name: 'First-Time Guests?' },
            { id: 'oneTimePerGuest', name: 'One-time Use per Guest?' },
          ]}
        />
        <CheckboxGroupInput
          source="shops"
          validate={validateLocations}
          choices={getLocations(workLocations)}
        />
        <TextInput source="notes" />
        {promoProducts && promoProducts.includes('gift') && (
          <TextInput source="minimum_gift_amount" />
        )}
        {promoProducts && promoProducts.includes('appointment') && (
          <>
            <CheckboxGroupInput
              source="days"
              choices={[
                { id: 1, name: 'Monday' },
                { id: 2, name: 'Tuesday' },
                { id: 3, name: 'Wednesday' },
                { id: 4, name: 'Thursday' },
                { id: 5, name: 'Friday' },
                { id: 6, name: 'Saturday' },
                { id: 0, name: 'Sunday' },
              ]}
            />
            <DateTimeInput
              source="appt_start_date"
              label="Appointment Start Date/Time"
            />
            <DateTimeInput
              source="appt_end_date"
              label="Appointment End Date/Time"
            />
          </>
        )}
      </SimpleForm>
    </Create>
  );
};
