import { client } from '../services/api-client';
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';

const getWaiver = async (_resource, params) => {
  try {
    const url = constructUrl(
      `liability_waiver/guardian?email=${encodeURIComponent(params.email)}`,
      {
        pagination: false,
      },
    );
    const resp = await client(url, { authNeeded: true });
    return resp.form;
  } catch (err) {
    showToast(err.message, 'error');
  }
};

const createWaiver = async (_resource, params) => {
  try {
    const { data } = params;
    const url = constructUrl('liability_waiver/guardian', {
      pagination: false,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data,
    });
    return resp;
  } catch (err) {
    showToast(err.message, 'error');
  }
};

export { getWaiver, createWaiver };
