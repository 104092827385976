import * as React from 'react';
import { Layout } from 'react-admin';
import { MyAppBar } from './app-bar';
import { ProfileProvider } from '../profile';

export const MyLayout = props => (
  <ProfileProvider>
    <Layout {...props} appBar={MyAppBar} />
  </ProfileProvider>
);
