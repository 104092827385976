import * as React from 'react';
import { Loading } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { FocusPressureBack } from './focus-pressure-back';
import { updatePreferences } from '../../services/preferences';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { useAppointment } from '../../hooks/useAppointment';

export const BackPreference = () => {
  let { userId, appointmentId } = useParams();

  const {
    userPreferences,
    loadingUserPreferences,
    // userPreferencesError,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const {
    data: appointment,
    // loading: loadAppointment,
    // error: appointmentError,
    // refetch: refetchAppointment,
  } = useAppointment({ appointmentId });

  const focusPressureBackProps = {
    updatePreferences,
    refetchUserPreferences,
    // setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    // history,
    appointmentId,
    userId,
    appointment,
    step: '2',
    // resetUpdatedPreferences,
    isFromSummary: true,
    pressure: appointment?.pressure,
  };

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Back Preferences" />
      <CardContent>
        {loadingUserPreferences && <Loading />}
        {!loadingUserPreferences && (
          <div class="flex-X flexrow-X">
            <FocusPressureBack className="m-4" {...focusPressureBackProps} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};
