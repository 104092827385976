import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { createTheme } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AppointmentIcon from '@material-ui/icons/DateRange';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import Policy from '@material-ui/icons/Policy';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import StarIcon from '@material-ui/icons/Star';
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { Toaster } from 'react-hot-toast';
import { Route } from 'react-router-dom';
import { AppointmentList } from './appointment';
import authProvider from './auth-provider';
import { MyLayout } from './components/app-layout';
import Waiver from './components/waiver';
import sidebarSaga from './custom-sagas/side-bar-saga';
import { customDataProvider } from './data-provider';
import { TeamMemberEdit, TeamMemberList } from './employee';
import { InactiveUsersList } from './employee/inactive-users';
import { TeamMemberCreate } from './employee/member-create';
import { OtherStaffList } from './employee/other-staff-list';
import { OtherStaffView } from './employee/other-staff-view';
import { TherapistsList } from './employee/therapists-list';
import { GiftDetails } from './gift/gift-details';
import { GiftList } from './gift/gift-list';
import { GiftShipQueue } from './gift/gift-ship-queue';
import history from './history';
import { ProfileEdit } from './profile';
import { PromoCreate } from './promo/promo-create';
import PromoDetails from './promo/promo-details';
import { PromoList } from './promo/promo-list';
import { PromoView } from './promo/promo-view';
import { ReportsList } from './reports/reports-list';
import { ReviewList } from './reviews/review-list';
import { TipsList } from './tips/list';
import { UserList } from './user';
import { ForgotPassword } from './user/forgot-password';
import { GuestDetails } from './user/guest-details';
import { BackPreference } from './user/guest-details/back-preference';
import { FrontPreference } from './user/guest-details/front-preference';
import { TherapistNotes } from './user/guest-details/therapist-notes';
import { GuestPreferences } from './user/guest-preferences';
import { GuestBackPreference } from './user/guest-preferences/guest-back-preference';
import { GuestFrontPreference } from './user/guest-preferences/guest-front-preference';
import { LoginPage } from './user/loginPage';
import { UserEdit } from './user/user-edit';
import {
  isAdmin,
  isBreakroom,
  isFrontDesk,
  isGss,
  isOperatingPartner,
  isShopManager,
  isTherapist,
} from './utils';
import { toasterConfig } from './utils/constants';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#8DD1D8',
    },
    // type: 'dark', // Switching the dark mode on is a single property value change.
  },
});

const CUSTOM_ROUTES = [
  <Route
    exact
    path="/guest/appointment/:appointmentId/view-guest-details/:userId"
    component={GuestDetails}
  />,
  <Route
    exact
    path="/guest/appointment/:appointmentId/view-guest-details/:userId/back-preferences"
    component={BackPreference}
  />,
  <Route
    exact
    path="/guest/appointment/:appointmentId/view-guest-details/:userId/front-preferences"
    component={FrontPreference}
  />,
  <Route
    exact
    path="/guest/:userId/appointment/:appointmentId/therapist-notes"
    component={TherapistNotes}
  />,
  <Route
    exact
    path="/employee/appointment/:appointmentId/view-guest-details/:userId"
    component={GuestDetails}
  />,
  <Route
    exact
    path="/gift/view-gift-card-details/:giftCardId"
    component={GiftDetails}
  />,
  <Route
    exact
    path="/guest/preferences/:userId"
    component={GuestPreferences}
  />,
  <Route
    exact
    path="/guest/view-guest-details/:userId/back-preferences"
    component={GuestBackPreference}
  />,
  <Route
    exact
    path="/guest/view-guest-details/:userId/front-preferences"
    component={GuestFrontPreference}
  />,
  <Route exact path="/promo/details/:promoId" component={PromoDetails} />,
  <Route exact path="/gift/ship-queue" component={GiftShipQueue} />,
  <Route exact path="/employee/therapists" component={TherapistsList} />,
  <Route exact path="/employee/other-staff" component={OtherStaffList} />,
  <Route exact path="/employee/inactive-users" component={InactiveUsersList} />,
  <Route
    exact
    path="/employee/other-staff/:staffId"
    component={OtherStaffView}
  />,
  <Route exact path="/employee/edit/:therapistId" component={TeamMemberEdit} />,
  <Route key="profile" path="/profile" component={ProfileEdit} />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
];
const App = () => (
  <>
    <Toaster {...toasterConfig} />
    <Admin
      history={history}
      customRoutes={CUSTOM_ROUTES}
      customSagas={[sidebarSaga]}
      authProvider={authProvider}
      dataProvider={customDataProvider}
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
    >
      {permissions => {
        if (
          (isAdmin(permissions) ||
            isTherapist(permissions) ||
            isBreakroom(permissions) ||
            isShopManager(permissions) ||
            isOperatingPartner(permissions) ||
            isGss(permissions) ||
            isFrontDesk(permissions)) &&
          JSON.parse(window.localStorage.getItem('force_password_reset'))
        )
          return [<Resource name="profile" />];
        else if (isTherapist(permissions))
          return [
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource name="review" list={ReviewList} icon={StarIcon} />,
            <Resource name="tip" list={TipsList} />,
          ];
        else if (isBreakroom(permissions))
          return [
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource name="minor waiver" list={Waiver} icon={Policy} />,
          ];
        else if (isAdmin(permissions))
          return [
            <Resource
              name="guest"
              list={UserList}
              edit={UserEdit}
              icon={PersonIcon}
            />,
            <Resource
              options={{ label: 'Squad' }}
              name="employee"
              list={TeamMemberList}
              create={TeamMemberCreate}
              edit={TeamMemberEdit}
              icon={GroupIcon}
            />,
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource
              name="promo"
              list={PromoList}
              create={PromoCreate}
              edit={PromoView}
              icon={AttachMoneyIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
            <Resource name="review" list={ReviewList} icon={StarIcon} />,
            <Resource
              name="report"
              list={ReportsList}
              icon={PollOutlinedIcon}
            />,
          ];
        else if (isShopManager(permissions))
          return [
            <Resource
              name="guest"
              list={UserList}
              edit={UserEdit}
              icon={PersonIcon}
            />,
            <Resource
              options={{ label: 'Squad' }}
              name="employee"
              list={TeamMemberList}
              create={TeamMemberCreate}
              edit={TeamMemberEdit}
              icon={GroupIcon}
            />,
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
            <Resource
              name="promo"
              list={PromoList}
              edit={PromoView}
              // create={PromoCreate}
              icon={AttachMoneyIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
            <Resource name="review" list={ReviewList} icon={StarIcon} />,
            <Resource
              name="report"
              list={ReportsList}
              icon={PollOutlinedIcon}
            />,
          ];
        else if (isOperatingPartner(permissions))
          return [
            <Resource
              name="guest"
              list={UserList}
              edit={UserEdit}
              icon={PersonIcon}
            />,
            <Resource
              options={{ label: 'Squad' }}
              name="employee"
              list={TeamMemberList}
              create={TeamMemberCreate}
              edit={TeamMemberEdit}
              icon={GroupIcon}
            />,
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource
              name="promo"
              list={PromoList}
              edit={PromoView}
              // create={PromoCreate}
              icon={AttachMoneyIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
            <Resource name="review" list={ReviewList} icon={StarIcon} />,
            <Resource
              name="report"
              list={ReportsList}
              icon={PollOutlinedIcon}
            />,
          ];
        else if (isGss(permissions))
          return [
            <Resource
              name="guest"
              list={UserList}
              edit={UserEdit}
              icon={PersonIcon}
            />,
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource
              name="promo"
              list={PromoList}
              create={PromoCreate}
              edit={PromoView}
              icon={AttachMoneyIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
            <Resource name="review" list={ReviewList} icon={StarIcon} />,
          ];
        else if (isFrontDesk(permissions))
          return [
            <Resource
              name="guest"
              list={UserList}
              edit={UserEdit}
              icon={PersonIcon}
            />,
            <Resource
              name="appointment"
              list={AppointmentList}
              icon={AppointmentIcon}
            />,
            <Resource
              name="promo"
              list={PromoList}
              // edit={PromoView}
              // show={PromoDetails}
              icon={AttachMoneyIcon}
            />,
            <Resource name="gift" list={GiftList} icon={CardGiftcardIcon} />,
          ];
      }}
    </Admin>
  </>
);

export default App;
