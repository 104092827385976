import {
  Datagrid,
  DateInput,
  Filter,
  FunctionField,
  List,
  SearchInput,
  SelectInput,
  TextField
} from 'react-admin';
import { getFormattedDateTime, getUtcToLocalDateTime } from '../utils';
const TipsFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="name" placeholder="Name" />
    <SearchInput source="amount_lower" placeholder="Amount >" />
    <SearchInput source="amount_upper" placeholder="Amount <" />
    {/* <DatePicker source="tip_date_lower" /> */}
    <SelectInput
      className="select-no-label"
      placeholder="Tip Type"
      source="type"
      choices={tipTypeOptions}
    />
    <DateInput source="tip_date_lower" label="Tip Date >" />
    <DateInput source="tip_date_upper" label="Tip Date <" />
    <DateInput source="start_at_upper" label="Service Date <" />
    <DateInput source="start_at_lower" label="Service Date >" />
  </Filter>
);

const tipTypeOptions = [
  { id: 'tip', name: 'Tip' },
  { id: 'no_tip', name: 'No Tip' },
  { id: 'cash', name: 'Cash' },
];
export const TipsList = ({ ...props }) => {
  return (
    <List
      {...props}
      filters={<TipsFilter />}
      perPage={25}
      className="main-table"
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid>
        <FunctionField
          label="Tip Date"
          render={record => getFormattedDateTime(record.created_at)}
        />
        <FunctionField
          label="Guest"
          render={record => (
            <span>
              {record?.user?.first_name} {record?.user?.last_name?.charAt(0)}
            </span>
          )}
        />
        <FunctionField
          label="Service Date"
          render={record =>
            record?.appointment?.start_at
              ? getUtcToLocalDateTime(record.appointment.start_at)
              : ''
          }
        />
        <FunctionField
          label="Amount"
          render={record =>
            record?.amount && record?.amount > 0 ? `$${record?.amount}` : ''
          }
        />
        <TextField source="type" label="Type" />
      </Datagrid>
    </List>
  );
};
