/* eslint-disable import/no-anonymous-default-export */
import { constructUrl } from '../utils/api';
import { getDateTime, showToast } from '../utils';
const { client } = require('../services/api-client');

const getPromoList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;
    const url = constructUrl('coupon', {
      filters: filter,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getPromoList - promo', 'error');
    return Promise.reject(err);
  }
};

const getPromo = async (_resource, params) => {
  try {
    const { id } = params;
    const url = constructUrl(`coupon?filters=id=${id}`, { pagination: false });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items[0],
    };
  } catch (err) {
    showToast('Error in get promo', 'error');
    return Promise.reject(err);
  }
};

const createPromo = async (_resource, params) => {
  const {
    code,
    capacity,
    promo_start_date,
    promo_end_date,
    notes,
    minimum_gift_amount,
    type,
    discount_amount,
    restrictions,
    products,
    days,
    appt_start_date,
    appt_end_date,
    shops,
  } = params.data;
  const formattedStartTime =
    getDateTime(appt_start_date) && getDateTime(appt_start_date).split(' ')[1];
  const formattedEndTime =
    getDateTime(appt_end_date) && getDateTime(appt_end_date).split(' ')[1];
  const formattedPromoStartTime =
    getDateTime(promo_start_date) &&
    getDateTime(promo_start_date).split(' ')[1];
  const formattedPromoEndTime =
    getDateTime(promo_end_date) && getDateTime(promo_end_date).split(' ')[1];

  const dataObj = {
    code: code,
    is_discount_fixed: true,
    capacity: capacity,
    promo_start_date: `${getDateTime(
      promo_start_date,
      false,
    )} ${formattedPromoStartTime}`,
    promo_end_date: `${getDateTime(
      promo_end_date,
      false,
    )} ${formattedPromoEndTime}`,
    appt_start_time: formattedStartTime,
    appt_end_time: formattedEndTime,
    products,
    locations: shops,
    restrictions,
    notes: notes,
    minimum_gift_amount: minimum_gift_amount,
    appt_day_of_week: days,
    appt_start_date: getDateTime(appt_start_date, false),
    appt_end_date: getDateTime(appt_end_date, false),
  };
  if (type === 1) {
    dataObj.discount_amount = discount_amount;
  } else {
    dataObj.discount_percentage = discount_amount;
  }

  const url = constructUrl('coupon', {});
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: dataObj,
    });
    return { data: resp };
  } catch (err) {
    showToast('Error in createPromo - promo', 'error');
    return Promise.reject(err);
  }
};

const updatePromo = async (_resource, params) => {
  const { id, data } = params;
  const url = constructUrl(`coupon/${id}`, { pagination: false });

  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data,
    });
    return { data: resp };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

export { getPromoList, createPromo, getPromo, updatePromo };
