/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getAppointment } from '../appointment/appointment-queries';

export const useAppointment = ({ appointmentId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAppointment = async () => {
    try {
      setLoading(true);
      const userPreferencesResponse = await getAppointment('appointment', {
        id: appointmentId,
      });
      setData(userPreferencesResponse.data);
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  };
  useEffect(() => {
    fetchAppointment();
  }, [appointmentId]);

  return {
    data,
    loading,
    error,
    refetch: fetchAppointment,
  };
};
