import React from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';
// import { CSVLink, CSVDownload } from 'react-csv';

const GiftsReport = ({ filters, otherFilters }) => {
  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'giftsReport',
    payload: {
      filters: { ...filters, ...otherFilters },
    },
  });
  if (loading) return <Loading />;

  return (
    <>
      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report/gifts',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
        }}
      >
        <Datagrid>
          <FunctionField label="Status" render={record => record.name} />
          <FunctionField
            label="Gift Card Value"
            render={record => record.gift_card_value}
          />
          <FunctionField
            label="Amount Charged"
            render={record => Number(record.amount_charged).toFixed(2)}
          />
        </Datagrid>
      </ListContextProvider>
      {/* <CSVLink data={data ? data : ''}>Download me</CSVLink> */}
    </>
  );
};
export default GiftsReport;
