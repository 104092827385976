import React from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';

const ScoreCardReport = ({ filters, otherFilters }) => {
  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'scoreboard-report',
    payload: {
      filters: { ...filters, ...otherFilters },
    },
  });
  if (loading) return <Loading />;

  return (
    <>
      <b>KPI Score Card Report</b>

      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
        }}
        // pagination={<PostPagination />}
        // perPage={perPage}
      >
        <Datagrid>
          <FunctionField label="" render={record => record.name} />
          <FunctionField label="Total " render={record => record.value} />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};
export default ScoreCardReport;
