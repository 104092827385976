import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useQuery,
  Pagination,
  FunctionField,
} from 'react-admin';
import { keyBy } from 'lodash';
import { GUESTS } from '../utils/constants';
import {
  getFormattedDateTime,
  getUtcToLocalDateTime,
  isTherapist,
} from '../utils';
import { useParams } from 'react-router-dom';

export const UserTips = ({ card, ...props }) => {
  const { therapistId } = useParams();
  const role = localStorage.getItem('role');
  const user = JSON.parse(localStorage.getItem('user'));
  const _therapistId = isTherapist(role) ? user?.id : null;
  const filter =
    card === GUESTS
      ? { user_id: props.id }
      : { therapist_id: therapistId || _therapistId };

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total } = useQuery({
    type: 'getList',
    resource: 'tip',
    payload: { pagination: { page, perPage }, filter },
  });

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  return (
    <ListContextProvider
      value={{
        resource: 'tip',
        basePath: `/guest/${props.id}`,
        data: keyBy(data, 'id'),
        ids: data && data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
        total,
        page,
        perPage,
        setPerPage,
        setPage,
      }}
      // pagination={<PostPagination />}
      perPage={perPage}
    >
      <Datagrid>
        {card && card === 'guests' && <TextField label="ID" source="id" />}
        <FunctionField
          label="Tip Date"
          render={record => getUtcToLocalDateTime(record.created_at)}
        />
        <FunctionField
          label="Service Date"
          render={record =>
            record?.appointment?.start_at
              ? getFormattedDateTime(
                  record.appointment.start_at,
                  record.appointment.timezone,
                )
              : ''
          }
        />
        {card && card === 'team_members' ? (
          <FunctionField
            label="Guest"
            render={record =>
              record?.user?.first_name ? record.user.first_name : ''
            }
          />
        ) : (
          <FunctionField
            label="Therapist"
            render={record =>
              record?.therapist?.first_name ? record.therapist.first_name : ''
            }
          />
        )}

        <FunctionField
          label="Amount"
          render={record =>
            record?.amount && record?.amount > 0 ? `$${record?.amount}` : ''
          }
        />
        <TextField source="type" label="Type" />
      </Datagrid>
      <PostPagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
      />
    </ListContextProvider>
  );
};
