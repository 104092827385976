import _ from 'lodash';
import moment from 'moment';
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const addExtraDay = date => {
  return date ? moment(date).add(1, 'days').format('YYYY-MM-DD') : '';
};

const getTherapistsRatingReport = async (_resource, params) => {
  try {
    const { filters, pagination } = params;
    filters.dateUpper = addExtraDay(filters.dateUpper);
    const url = constructUrl(`report/therapist/rating`, {
      filters,
      pagination,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getTipsReport = async (_resource, params) => {
  try {
    const { filters, pagination } = params;
    filters.dateUpper = addExtraDay(filters.dateUpper);
    const url = constructUrl(`report/therapist/tips`, {
      filters,
      pagination,
    });
    const resp = await client(url, { authNeeded: true });
    const final_resp = resp?.items && resp.items.filter(t => t.sum > 0);
    final_resp.push({
      full_name: 'Total',
      id: '11',
      sum: resp.totalSum,
    });
    return {
      data: final_resp,
      total: { total: resp.count, sum: resp.totalSum },
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getHallSqueezersReport = async (_resource, params) => {
  try {
    const { filters, pagination } = params;
    const url = constructUrl(`report/appointment/count`, {
      filters,
      pagination,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getScoreboardReport = async (_resource, params) => {
  try {
    const { filters } = params;
    filters.dateUpper = addExtraDay(filters.dateUpper);
    const url = constructUrl(`report/scorecard`, {
      filters,
      pagination: false,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getCancelMembershipReportList = async (_resource, params) => {
  try {
    const { filters, pagination } = params;
    filters.dateUpper = addExtraDay(filters.dateUpper);
    const url = constructUrl(`report/membership/cancel`, {
      filters,
      pagination,
    });
    const resp = await client(url, { authNeeded: true });
    const sortByCancellation = resp && _.sortBy(resp?.items, ['cancelled_at']);
    return {
      data: sortByCancellation,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getGiftsReport = async (_resource, params) => {
  try {
    const { filters } = params;
    const { location_id } = filters;
    filters.redeemedAtUpper = addExtraDay(filters.redeemedAtUpper);
    filters.purchaseDateUpper = addExtraDay(filters.purchaseDateUpper);
    let url = '';
    if (location_id === 'all') {
      delete filters.location_id;
      url = constructUrl(`report/user/gifts`, {
        filters,
        pagination: false,
      });
    } else {
      url = constructUrl(`report/user/gifts`, {
        filters,
        pagination: false,
      });
    }
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getPromoRevenueReport = async (_resource, params) => {
  try {
    const { filters } = params;
    filters.txnDateUpper = addExtraDay(filters.txnDateUpper);
    const url = constructUrl(`report/promo/revenue`, {
      filters,
      pagination: false,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

const getDistributionReport = async (_resource, params) => {
  try {
    const { filters } = params;
    filters.dateUpper = addExtraDay(filters.dateUpper);
    const url = constructUrl(`report/distribution`, {
      filters,
      pagination: false,
    });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast('Error in getReportList - Distribution', 'error');
    return Promise.reject(err);
  }
};

const getSqueezeBucksRevenueReport = async (_resource, params) => {
  try {
    const { filters } = params;
    const url = constructUrl(`report/user/wallet`, {
      filters,
      pagination: false,
    });
    const resp = await client(url, { authNeeded: true });
    let _resp = [];
    _resp.push({
      id: resp['id'],
      name: 'Squeeze Bucks Balance',
      value: resp['totalSum'],
    });
    return {
      data: _resp,
      total: null,
    };
  } catch (err) {
    showToast('Error in getReportList - tip', 'error');
    return Promise.reject(err);
  }
};

export {
  getTherapistsRatingReport,
  getTipsReport,
  getScoreboardReport,
  getHallSqueezersReport,
  getCancelMembershipReportList,
  getGiftsReport,
  getPromoRevenueReport,
  getSqueezeBucksRevenueReport,
  getDistributionReport,
};
