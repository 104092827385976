import * as React from 'react';
import { Loading } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { updatePreferences } from '../../services/preferences';
import FocusPressureFront from './focus-pressure-front';

import { useAppointment } from '../../hooks/useAppointment';

export const FrontPreference = () => {
  let { userId, appointmentId } = useParams();

  const {
    userPreferences,
    loadingUserPreferences,
    // userPreferencesError,
    refetchUserPreferences,
  } = useUserPreferences({ userId });

  const {
    data: appointment,
    // loading: loadAppointment,
    // error: appointmentError,
    // refetch: refetchAppointment,
  } = useAppointment({ appointmentId });

  const focusPressureFrontProps = {
    updatePreferences,
    refetchUserPreferences,
    // setShowPreference,
    preferences: {
      preferencesArray: userPreferences,
      fetchingPreferences: loadingUserPreferences,
      updatingPreferences: loadingUserPreferences,
    },
    // history,
    step: '2',
    appointmentId,
    userId,
    appointment,
    // resetUpdatedPreferences,
    isFromSummary: true,
    pressure: appointment?.pressure,
  };

  return (
    <Card className="mt-4 force-scroll">
      <Title title="Front Preferences" />
      <CardContent>
        {loadingUserPreferences && <Loading />}
        {!loadingUserPreferences && (
          <div class="flex-X flex-row-X">
            <FocusPressureFront className="m-4" {...focusPressureFrontProps} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};
