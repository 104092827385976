import { useRef, useState } from 'react';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import { customDataProvider } from '../data-provider';
import '../styles/waiver.css';
import { showToast } from '../utils';

const Waiver = () => {
  const emailRef = useRef();
  const sigCanvas = useRef();
  const scroll = useRef();

  // State for external form
  const [name, setGuardianName] = useState('');
  const [email, setGuardianEmail] = useState('');
  const [phone, setGuardianPhone] = useState('');

  // State for handling modal/sign
  const [page, setPage] = useState();
  const [openModel, setOpenModal] = useState(false);
  const [sign, setSign] = useState();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '3rem',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '100%',
      overflow: 'auto',
    },
  };

  /**
   * Add event-listeners to PDF form inputs and fetch guardian details
   * @param {*} event
   * @returns
   */
  const handleSubmit = async event => {
    event.preventDefault();
    if (!emailRef.current.value) {
      showToast('Enter an email id!', 'error');
      return;
    }

    customDataProvider
      .getOne('waiver', {
        email: emailRef.current.value,
      })
      .then(page => {
        if (page) {
          setPage(page);
          setOpenModal(true);
          // Add event listner to form elements inside the Waiver PDF
          const guardianName = document.querySelectorAll('input')[1];
          const guardianEmail = document.querySelectorAll('input')[2];
          const guardianPhone = document.querySelectorAll('input')[3];
          if (
            guardianEmail !== undefined &&
            guardianName !== undefined &&
            guardianPhone !== undefined
          ) {
            guardianName.classList.add('border-2');
            guardianName.value = '';

            guardianEmail.classList.add('border-2');
            guardianEmail.value = '';

            guardianPhone.classList.add('border-2');
            guardianPhone.value = '';

            guardianName.addEventListener('input', () => {
              setGuardianName(guardianName.value);
            });

            guardianEmail.addEventListener('input', () => {
              setGuardianEmail(guardianEmail.value);
            });

            guardianPhone.addEventListener('input', () => {
              setGuardianPhone(guardianPhone.value);
            });
          }
        } else {
          setOpenModal(false);
        }
      })
      .catch(err => {
        setOpenModal(false);
        showToast(err.message, 'error');
      });
  };

  /**
   * Capture sign from the sign-pad
   */
  const handleSign = () => {
    const sign = sigCanvas.current.getTrimmedCanvas().toDataURL();
    setSign(sign);
  };

  /**
   * Submit the sign and guardian details
   */
  const submitForm = async () => {
    if (
      !sign ||
      sign ===
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NgAAIAAAUAAR4f7BQAAAAASUVORK5CYII='
    ) {
      showToast('Please sign the waiver', 'error');
      return;
    }
    customDataProvider
      .create('waiver', {
        data: {
          guardianName: name,
          guardianPhone: phone,
          guardianEmail: email,
          email: emailRef.current.value,
          imageString: sign,
        },
      })
      .then(
        resp => {
          if (resp?.url) {
            setOpenModal(false);
            setGuardianName('');
            setGuardianEmail('');
            setGuardianPhone('');
            emailRef.current.value = '';
            showToast('Waiver was signed successfully!', 'success');
          }
        },
        err => {
          emailRef.current.value = '';
          setOpenModal(false);
          showToast(err.message);
          return;
        },
      );
  };

  return (
    <>
      <div className="component-heading">
        Guardian Liability Waiver for Minor Guests
      </div>
      <form onSubmit={handleSubmit}>
        <div className="space-x-6">
          <label htmlFor="email">Minor Guest's Mail</label>
          <input className="border-2" id="email" ref={emailRef} type="text" />
        </div>
        <button className="btn btn--secondary" type="submit">
          Submit
        </button>
      </form>
      <Modal className='liability-popup' isOpen={openModel} style={customStyles}>
        <div className="signContainer liabilityContainer">
          <header className="header-hdr">
            <h1
              className="header-font own-header ucase mt10"
              title={'LIABILITY WAIVER AND RELEASE'}
            >
              LIABILITY WAIVER AND RELEASE
            </h1>
          </header>
          <div className="d-flex-center mb-3">
            <button
              className="btn btn--secondary"
              onClick={() => {
                scroll.current.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Sign Now
            </button>
          </div>
          <div dangerouslySetInnerHTML={{ __html: page }} />
          <div className="signWaiver new-sign">
            <div className="mt-10">
              <div className="sigContainer">
                <h6
                  className="header-font own-header newfont"
                  title={'Digitally Sign Below'}
                >
                  Digitally Sign Below
                </h6>
                <SignatureCanvas ref={sigCanvas} />
                <div className="d-flex-between">
                  <button
                    className="btn"
                    onClick={() => {
                      sigCanvas.current.clear();
                      sigCanvas.current.value = '';
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn--secondary"
                    onClick={() => {
                      handleSign();
                      submitForm();
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div ref={scroll}></div>
        </div>
      </Modal>
    </>
  );
};

export default Waiver;
