import { useEffect, useState } from 'react';
import { getAppointmentTherapistNotes } from '../appointment/appointment-queries';

export const useAppointmentTherapistNotes = ({ userId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAppointmentTherapistNotes = async () => {
    try {
      setLoading(true);
      const therapistNotesResponse = await getAppointmentTherapistNotes(
        'appointment',
        { userId },
      );
      setData(therapistNotesResponse.data);
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  };
  useEffect(() => {
    fetchAppointmentTherapistNotes();
  }, [userId]);

  return {
    data,
    loading,
    error,
    refetch: fetchAppointmentTherapistNotes,
  };
};
