import React from 'react';
import { TextInput, SimpleForm, SaveButton } from 'react-admin';

export const ForgotPassword = () => {
  const handleSave = form => {
    const { email } = form;
  };

  const Toolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Submit" onSave={handleSave} />
    </Toolbar>
  );

  return (
    <SimpleForm toolbar={<Toolbar />}>
      <TextInput label="Email" source="email" />
    </SimpleForm>
  );
};
