import React, { useState } from 'react';
import {
  SimpleForm,
  Edit,
  TextField,
  Toolbar,
  SaveButton,
  TextInput,
  CheckboxGroupInput,
  DateTimeInput,
  useQuery,
  useEditController,
  required,
} from 'react-admin';
import '../user-styles.css';
import {
  getDateTime,
  getLocations,
  getFormattedDate_T,
  showToast,
  getUTCDate,
} from '../utils';
import { customDataProvider } from '../data-provider';

const validateProducts = [required()];
const validateLocations = [required()];
const validateValue = [required()];
const validateDate = [required()];
const validateCapacity = [required()];

export const PromoView = props => {
  const controllerProps = useEditController({
    basePath: `/promo/${props.id}`,
    permissions: 'admin',
    resource: 'promo',
    id: props.id,
  });

  const promoDetails = controllerProps.record;
  const [promoProducts, setPromoProducts] = useState(promoDetails?.products);

  const handlePromoUpdate = formValues => {
    const {
      promo_start_date,
      promo_end_date,
      capacity,
      discount_amount,
      discount_percentage,
      products,
      appt_day_of_week,
      appt_end_date,
      appt_start_date,
      locations,
      minimum_gift_amount,
      notes,
      restrictions,
    } = formValues;

    const _appt_start_date = getUTCDate(appt_start_date);
    const _appt_end_date = getUTCDate(appt_end_date);
    const _promo_start_date = getUTCDate(promo_start_date);
    const _promo_end_date = getUTCDate(promo_end_date);

    const formattedStartTime =
      getDateTime(_appt_start_date) &&
      getDateTime(_appt_start_date).split(' ')[1];
    const formattedEndTime =
      getDateTime(_appt_end_date) && getDateTime(_appt_end_date).split(' ')[1];
    const formattedPromoStartTime =
      getDateTime(_promo_start_date) &&
      getDateTime(_promo_start_date).split(' ')[1];
    const formattedPromoEndTime =
      getDateTime(_promo_end_date) &&
      getDateTime(_promo_end_date).split(' ')[1];

    let data = {
      capacity,
      products,
      locations,
      minimum_gift_amount,
      notes: notes ? notes : '',
      restrictions: restrictions ? restrictions : [],
    };
    if (formValues.promo_start_date != promoDetails.promo_start_date)
      data.promo_start_date = `${getDateTime(
        _promo_start_date,
        false,
      )} ${formattedPromoStartTime}`;

    if (formValues.promo_end_date != promoDetails.promo_end_date)
      data.promo_end_date = `${getDateTime(
        _promo_end_date,
        false,
      )} ${formattedPromoEndTime}`;

    if (formValues.appt_start_date != promoDetails.appt_start_date)
      data.appt_start_date = getDateTime(_appt_start_date, false);
    if (formValues.appt_end_date != promoDetails.appt_end_date)
      data.appt_end_date = getDateTime(_appt_end_date, false);

    if (formValues.appt_start_time != promoDetails.appt_start_time)
      data.appt_start_time = formattedStartTime;
    if (formValues.appt_end_time != promoDetails.appt_end_time)
      data.appt_end_time = formattedEndTime;

    formValues.discount_amount
      ? (data.discount_amount = formValues.discount_amount)
      : (data.discount_percentage = formValues.discount_percentage);
    if (formValues.appt_day_of_week)
      data.appt_day_of_week = formValues.appt_day_of_week;

    customDataProvider.update('promo', { id: props.id, data }).then(() => {
      showToast('Promo updated succesfully');
    });
  };

  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));

  const EditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={handlePromoUpdate} />
    </Toolbar>
  );

  return (
    <div className="details-display inline-block-imp">
      <Edit title="Edit Promo" {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextField source="code" label="Promo Code" />
          <DateTimeInput
            source="promo_start_date"
            label="Start Date"
            render={record => getFormattedDate_T(record?.promo_start_date)}
            validate={validateDate}
          />
          <DateTimeInput
            source="promo_end_date"
            label="End Date"
            render={record => getFormattedDate_T(record?.promo_end_date)}
            validate={validateDate}
          />
          <TextInput source="capacity" validate={validateCapacity} />
          {promoDetails?.discount_amount ? (
            <TextInput
              source="discount_amount"
              label="Discount Amount"
              validate={validateValue}
            />
          ) : (
            <TextInput
              source="discount_percentage"
              label="Discount Percentage"
              validate={validateValue}
            />
          )}
          <CheckboxGroupInput
            source="products"
            validate={validateProducts}
            label="Products"
            onChange={e => setPromoProducts(e)}
            choices={[
              { id: 'appointment', name: 'Appointment' },
              { id: 'gift', name: 'Gift' },
              { id: 'membership', name: 'Membership' },
            ]}
          />
          <CheckboxGroupInput
            source="restrictions"
            choices={[
              { id: 'membersOnly', name: 'Members only?' },
              { id: 'firstTimeGuest', name: 'First-Time Guests?' },
              { id: 'oneTimePerGuest', name: 'One-time Use per Guest?' },
            ]}
          />
          <CheckboxGroupInput
            source="locations"
            validate={validateLocations}
            choices={getLocations(workLocations)}
          />
          <TextInput source="notes" />
          {promoProducts && promoProducts.includes('gift') && (
            <TextInput source="minimum_gift_amount" />
          )}
          {promoProducts && promoProducts.includes('appointment') && (
            <>
              <CheckboxGroupInput
                source="appt_day_of_week"
                choices={[
                  { id: 1, name: 'Monday' },
                  { id: 2, name: 'Tuesday' },
                  { id: 3, name: 'Wednesday' },
                  { id: 4, name: 'Thursday' },
                  { id: 5, name: 'Friday' },
                  { id: 6, name: 'Saturday' },
                  { id: 0, name: 'Sunday' },
                ]}
              />
              <DateTimeInput
                source="appt_start_date"
                label="Appointment Start Date/Time"
              />
              <DateTimeInput
                source="appt_end_date"
                label="Appointment End Date/Time"
              />
            </>
          )}
        </SimpleForm>
      </Edit>
    </div>
  );
};
