import { Link } from 'react-router-dom';
import Select from 'react-select';
import '../../assets/main.css';
import { isTherapist } from '../../utils';
import { MASSAGE_REASON_OPTIONS } from '../../utils/constants';

export const Details = ({
  refetchUserPreferences,
  updatePreferences,
  preferences: { preferencesArray },
  userId,
  user,
}) => {
  const onMassageReasonChange = item => {
    updatePreferences({
      id: userId,
      preferences: { massage_reason: item?.value },
    }).then(() => refetchUserPreferences());
  };

  const massagePreference = preferencesArray?.find(
    preference => preference.key === 'massage_reason',
  );
  const extraInfo = preferencesArray?.find(
    preference => preference.key === 'extra_info',
  );
  const role = localStorage.getItem('role');

  return (
    <>
      <div className="is-flex p-4 mt-4">
        <div className="flex flex-row pl-4 pr-4 pb-4">
          {isTherapist(role) ? (
            <h2 className="text-xl font-bold">
              {user.first_name} {user.last_name?.charAt(0)}
            </h2>
          ) : (
            <h2 className="text-xl font-bold blueLink">
              <Link className="underline" to={`/guest/${userId}`}>
                {user.first_name} {user.last_name}
              </Link>
            </h2>
          )}
        </div>
        <div className="mb-8 px-4">
          <div className="font-semibold pb-2">
            Why are you getting a massage?
          </div>
          <div>
            {!massagePreference?.value && (
              <div class="flex flex-row p-2">
                <Select
                  className="w-60"
                  value={null}
                  isSearchable={false}
                  options={MASSAGE_REASON_OPTIONS}
                  onChange={item => onMassageReasonChange(item)}
                />
              </div>
            )}
            {massagePreference?.value && (
              <div>{massagePreference?.value || ''}</div>
            )}
          </div>
        </div>

        <div className="mb-8 px-4">
          <div className="font-semibold pb-2">
            Anything else you want the therapist to know?
          </div>
          <div>{extraInfo?.value}</div>
        </div>
      </div>
    </>
  );
};
