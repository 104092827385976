import { showToast } from '../utils';
import { client } from './api-client';

const { constructUrl } = require('../utils/api');

export const fetchPreferences = async userId => {
  try {
    // const filters = { user_id: userId };
    let url = constructUrl(`user/preferences`, {
      queryParams: `user_id=${userId}`,
      pagination: false,
      // filters,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};

export const updatePreferences = async params => {
  const url = constructUrl('user/preferences', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });
    return { data: resp };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};

export const finishAppointment = async (id, params) => {
  try {
    // const filters = { user_id: userId };
    let url = constructUrl(`appointment/${id}/close`, {
      // queryParams: `user_id=${userId}`,
      pagination: false,
      // filters,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return {
      data: resp,
      // total: resp.count,
    };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};

export const noshowAppointment = async (id, params) => {
  try {
    let url = constructUrl(`appointment/${id}/noshow`, {
      pagination: false,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return {
      data: resp,
    };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};

export const checkInAppointment = async id => {
  try {
    // const filters = { user_id: userId };
    let url = constructUrl(`appointment/${id}/checkin`, {
      // queryParams: `user_id=${userId}`,
      pagination: false,
      // filters,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: {},
    });

    return {
      data: resp,
      // total: resp.count,
    };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};

export const addTherapistNote = async (appointmentId, params) => {
  try {
    // const filters = { user_id: userId };
    let url = constructUrl(`appointment/${appointmentId}/notes`, {
      // queryParams: `user_id=${userId}`,
      pagination: false,
      // filters,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return {
      data: resp,
      // total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const cancelAppointment = async (id, params) => {
  try {
    let url = constructUrl(`appointment/${id}/cancel`, {
      pagination: false,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return {
      data: resp,
    };
  } catch (err) {
    showToast(err?.message, 'error');
    return Promise.reject(err);
  }
};
