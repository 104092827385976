import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Pagination,
  TextField,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';
// import { CSVLink, CSVDownload } from 'react-csv';

const TipsReport = ({ filters, otherFilters }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'tips-report',
    payload: {
      filters: { ...filters, ...otherFilters },
      pagination: { page, perPage },
    },
  });

  if (loading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[100]} {...props} />
  );

  return (
    <>
      {/* <div style={{ float: 'right' }}>
        <button className="button-blue">
          <CSVLink data={data ? data : ''}>Export </CSVLink>
        </button>
      </div>{' '} */}
      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report/tips',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'full_name', order: 'ASC' },
          selectedIds: [],
          total: total?.total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Therapist Name"
            render={record => record.full_name}
          />
          <FunctionField
            label="Total Tips"
            render={record => `$ ${record.sum}`}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total?.total}
        />
      </ListContextProvider>
    </>
  );
};
export default TipsReport;
